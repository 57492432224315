<template>
    <v-container grid-list-xl id="mainHomeHolder" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
        <!-- <v-layout row wrap id="bannerHolder"> -->
            <swiper :options="swiperOption">
                <swiper-slide class="slide-1">
                    <v-layout row wrap>
                        <v-flex md2 xs12></v-flex>
                        <v-flex md9 xs12>
                            <!-- <img src="../../assets/images/home/advancedSlideLeft.png" style="position: absolute; top: 50%; transform: translate(0%, -50%)"/> -->
                            <picture style="position: absolute; top: 50%; transform: translate(-30%, -50%)" class="hidden-sm-and-down">
                                <source srcset="../../assets/images/home/advancedSlideLeft.webp" type="image/webp">
                                <source srcset="../../assets/images/home/advancedSlideLeft.png" type="image/png"> 
                                <img src="../../assets/images/home/advancedSlideLeft.png" alt="Slide1">
                            </picture>
                            <picture style="position: absolute; top: 72%; width: 50%; height: 50%;" class="hidden-md-and-up">
                                <source srcset="../../assets/images/home/advancedSlideLeft.webp" type="image/webp">
                                <source srcset="../../assets/images/home/advancedSlideLeft.png" type="image/png"> 
                                <img src="../../assets/images/home/advancedSlideLeft.png" alt="Slide1">
                            </picture>
                        </v-flex>
                    </v-layout>
                </swiper-slide>
                <swiper-slide class="slide-2">
                    <v-layout row wrap>
                        <v-flex md2 xs12></v-flex>
                        <v-flex md9 xs12>
                            <!-- <img src="../../assets/images/home/tabletSlideLeft.png" style="position: absolute; top: 50%; transform: translate(0%, -50%)"/> -->
                            <picture style="position: absolute; top: 50%; transform: translate(0%, -50%)">
                                <source srcset="../../assets/images/home/tabletSlideLeft.webp" type="image/webp">
                                <source srcset="../../assets/images/home/tabletSlideLeft.png" type="image/png"> 
                                <img src="../../assets/images/home/tabletSlideLeft.png" alt="Slide2">
                            </picture>
                        </v-flex>
                    </v-layout>
                </swiper-slide>
                <swiper-slide class="slide-3">
                    <v-layout row wrap>
                        <v-flex md2 xs12></v-flex>
                        <v-flex md9 xs12>
                            <!-- <img src="../../assets/images/home/lazerSlideLeft.png" style="position: absolute; top: 50%; transform: translate(0%, -50%)"/> -->
                            <picture style="position: absolute; top: 50%; transform: translate(0%, -50%)">
                                <source srcset="../../assets/images/home/lazerSlideLeft.webp" type="image/webp">
                                <source srcset="../../assets/images/home/lazerSlideLeft.png" type="image/png"> 
                                <img src="../../assets/images/home/lazerSlideLeft.png" alt="Slide3">
                            </picture>
                        </v-flex>
                    </v-layout>
                </swiper-slide>
                <swiper-slide class="slide-4">
                    <v-layout row wrap>
                        <v-flex md2 xs12></v-flex>
                        <v-flex md9 xs12>
                            <!-- <img src="../../assets/images/home/cuttingLineSlideLeft.png" style="position: absolute; top: 50%; transform: translate(0%, -50%)"/> -->
                            <picture style="position: absolute; top: 50%; transform: translate(0%, -50%)">
                                <source srcset="../../assets/images/home/cuttingLineSlideLeft.webp" type="image/webp">
                                <source srcset="../../assets/images/home/cuttingLineSlideLeft.png" type="image/png"> 
                                <img src="../../assets/images/home/cuttingLineSlideLeft.png" alt="Slide4">
                            </picture>
                        </v-flex>
                    </v-layout>
                </swiper-slide>
                <swiper-slide class="slide-5">
                    <v-layout row wrap>
                        <v-flex md12 xs12>
                            <!-- <img src="../../assets/images/home/lastSlideLeft.png" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"/> -->
                            <picture style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" class="hidden-sm-and-down">
                                <source srcset="../../assets/images/home/lastSlideLeft.webp" type="image/webp">
                                <source srcset="../../assets/images/home/lastSlideLeft.png" type="image/png"> 
                                <img src="../../assets/images/home/lastSlideLeft.png" alt="Slide5">
                            </picture>
                            <picture style="position: absolute; top: 50%; transform: translate(0%, -50%)" class="hidden-md-and-up">
                                <source srcset="../../assets/images/home/lastSlideLeft.webp" type="image/webp">
                                <source srcset="../../assets/images/home/lastSlideLeft.png" type="image/png"> 
                                <img src="../../assets/images/home/lastSlideLeft.png" alt="Slide5">
                            </picture>
                        </v-flex>
                    </v-layout>
                </swiper-slide>
            </swiper>
        <!-- </v-layout> -->
        <v-layout row wrap>
            <white>
                <v-flex md4 xs12>
                    <picture style="cursor: pointer;" @click="openBrochureModal()">
                        <source srcset="../../assets/images/global/downloadProfistopBrochure.webp" type="image/webp">
                        <source srcset="../../assets/images/global/downloadProfistopBrochure.png" type="image/png"> 
                        <img src="../../assets/images/global/downloadProfistopBrochure.png" alt="PS Brochure">
                    </picture>
                </v-flex>
                <v-flex md12 xs12>
                    <!-- <img src="../../assets/images/global/auMade.png" /> -->
                    <picture>
                        <source srcset="../../assets/images/global/auMade.webp" type="image/webp">
                        <source srcset="../../assets/images/global/auMade.png" type="image/png"> 
                        <img src="../../assets/images/global/auMade.png" alt="AU Made">
                    </picture>
                </v-flex>
                <v-layout row wrap>
                    <v-flex md2 xs12></v-flex>
                    <v-flex md8 xs12>
                        <p class="descriptionDark">
                            ProfiStop&reg; is a range of measuring stop and auto material feeding systems suitable for 
                            transforming your saws into simple to use, automated, high-productivity workstations.
                            <br><br>
                            In addition to mechanical automation, ProfiStop&reg; streamlines your production management 
                            in the most intuitive ways possible, from the initial order to finished parts.
                        </p>
                        <h4 class="darkHeader">
                            Measuring Stop and Automatic Saw Professionals
                        </h4>
                    </v-flex>
                    <v-flex lg2 md2 xs12></v-flex>
                    <br>
                    <v-layout row wrap class="loading" v-if="loading">
                        <v-flex lg12 md12 sm12 xs12>
                            <half-circle-spinner style="transform: translate(-50%, 0); position: relative; left: 50%; margin-top: 50px; margin-bottom: 50px;"
                                :animation-duration="1000"
                                :size="60"
                                :color="'#0066cc'"
                            />
                        </v-flex>
                    </v-layout>
                </v-layout>
                <v-layout row wrap v-if="newsArticle">
                    <v-flex md1 xs12></v-flex>
                    <v-flex md5 xs12 class="newsHolders">
                        <v-layout row wrap>
                            <v-flex md6 xs12>
                                <span v-if="newsArticle[0].hasVideo == 1" v-html= newsArticle[0].articleImage></span>
                                <span v-if="newsArticle[0].hasVideo == 0" v-html= newsArticle[0].firstImage></span>
                            </v-flex>
                            <v-flex md6 xs12 v-if="newsArticle[0].hasVideo == 0">
                                <span v-html= newsArticle[0].secondImage></span>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex md12 xs12>
                                <p class="newsDescription" v-if="newsArticle[0].hasVideo == 1" v-html="newsArticle[0].title + ' ' + newsArticle[0].postDate"></p>
                                <p class="newsDescription" v-if="newsArticle[0].hasVideo == 0" v-html="newsArticle[0].paragraph.substring(0, 100) + '...'"></p>
                                <br>
                                <router-link :to="{ path: '/news/article/' + newsArticle[0].articleLink }">
                                    Read More
                                </router-link>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex md5 xs12 class="newsHolders">
                        <v-layout row wrap>
                            <v-flex md6 xs12>
                                <span v-if="newsArticle[1].hasVideo == 1" v-html= newsArticle[1].articleImage></span>
                                <span v-if="newsArticle[1].hasVideo == 0" v-html= newsArticle[1].firstImage></span>
                            </v-flex>
                            <v-flex md6 xs12 v-if="newsArticle[1].hasVideo == 0">
                                <span v-html= newsArticle[1].secondImage></span>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex md12 xs12>
                                <p class="newsDescription" v-if="newsArticle[1].hasVideo == 1" v-html="newsArticle[1].title + ' ' + newsArticle[1].postDate"></p>
                                <p class="newsDescription" v-if="newsArticle[1].hasVideo == 0" v-html="newsArticle[1].paragraph.substring(0, 100) + '...'"></p>
                                <br>
                                <router-link :to="{ path: '/news/article/' + newsArticle[1].articleLink }">
                                    Read More
                                </router-link>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex md1 xs12></v-flex>
                </v-layout>
            </white>
        </v-layout>
        <brochure-modal></brochure-modal>
        <p id="brochureType" style="display: none;">Profistop</p>
        <button type="button" class="swiper-button-next" ref="nextSld" id="nextSld" style="display: none;"></button>
    </v-container>
</template>
<script>
    import White from '../helper_components/White.vue';
    import axios from 'axios';
    import { HalfCircleSpinner } from 'epic-spinners';
    import BrochureModal from '../helper_components/BrochureModal.vue';
	export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'ProfiStop',
            // all titles will be injected into this template
            titleTemplate: '%s | Home',
            noscript: [{
                innerHTML: 'This website requires JavaScript.'
            }],
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: 'Do you cut aluminium, wood or steel? ProfiStop has the leading measuring stop and automatic saw solutions to transform your cutting production. Fast payback and ROI, game-changing efficiency. Contact Us Today!'
                },
                {
                    vmid: 'keywords',
                    name: 'keywords',
                    content: 'measuring stop, length stop, automatic saw, automatic cutting machine'
                }
            ],
        },
		components: {
            'white': White,
            'half-circle-spinner': HalfCircleSpinner,
            'brochure-modal': BrochureModal
        },
        data() {
            return {
                swiperOption: {
                    spaceBetween: 30,
                    effect: 'flip',
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                },
                newsArticle: [],
                error: null,
                loading: false
            }
        },
        created () {
            // fetch the data when the view is created and the data is
            // already being observed
            this.fetchData()
        },
        watch: {
            // call again the method if the route changes
            '$route': 'fetchData'
        },
        methods: {
            swipeLeft() {
                this.$router.push('/products')
            },
            swipeRight() {
                this.$router.push('/contact')
            },
            fetchData () {
                if(typeof this.$route.query.l != 'undefined') {
                    this.$router.replace(this.$route.query.l)
                }
                this.error = this.newsArticle = null;
                this.loading = true;
                var that = this;
                axios.post(`https://profistop.com/homeNews.php`, {
                })
                .then(function (response) {
                    that.newsArticle = response.data;
                    that.loading = false;
                })
                .catch(e => {
                    console.log(e);
                });
            },
            openBrochureModal() {
                document.getElementById('brochureTrigger').click();
            },
        },
    }
    setInterval(() => {
        var elem = document.getElementById('nextSld');
        if (elem != null && typeof elem != 'undefined') {
            elem.click();
        }
    }, 8000);
    
</script>
<style scoped>
    .swiper-slide {
        background-position: center;
        background-size: cover;
        min-height: 496px;
    }
    .no-webp .slide-1 {
        background-image: url(../../assets/images/home/advancedSlide.jpg)
    }
    .no-webp .slide-2 {
        background-image: url(../../assets/images/home/tabletSlide.jpg)
    }
    .no-webp .slide-3 {
        background-image: url(../../assets/images/home/lazerSlide.jpg)
    }
    .no-webp .slide-4 {
        background-image: url(../../assets/images/home/cuttingLineSlide.jpg)
    }
    .no-webp .slide-5 {
        background-image: url(../../assets/images/home/lastSlide.jpg)
    }
    .no-webp #bannerHolder {
        background: url(../../assets/images/home/lazerSlide.jpg);
        min-height: 496px;
        /* background-size: contain; */
    }
    .webp .slide-1 {
        background-image: url(../../assets/images/home/advancedSlide.webp)
    }
    .webp .slide-2 {
        background-image: url(../../assets/images/home/tabletSlide.webp)
    }
    .webp .slide-3 {
        background-image: url(../../assets/images/home/lazerSlide.webp)
    }
    .webp .slide-4 {
        background-image: url(../../assets/images/home/cuttingLineSlide.webp)
    }
    .webp .slide-5 {
        background-image: url(../../assets/images/home/lastSlide.webp)
    }
    .webp #bannerHolder {
        background: url(../../assets/images/home/lazerSlide.webp);
        min-height: 496px;
        /* background-size: contain; */
    }
	.newsHolders {
		background: #F2F2F2;
		border: 1px solid #FFF;
		margin: 20px 20px 100px 0;
	}
	.newsDescription {
		font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
		font-size: 16px;
		font-weight: bold;
		text-align: left;
		margin-bottom: 8px;
	}
    #faBottom {
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        /* bottom: 25px;
        right: -8%; */
    }
    #imageHolder {
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        bottom: 25px;
        right: -8%;
    }
    li {
        list-style: none;
        text-align: left;
        padding-left: 0px;
        display: block;
    }
    .darkLine {
        width: 120px;
        height: 3px;
        background-color: #222;
        margin-bottom: 16px;
    }
    #homeHolder {
        padding-bottom: 0;
        margin-bottom: -13px;
    }
    #mainHomeHolder {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    .descriptionDark {
        color: #2f2f2f;
        font-size: 21px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        margin-top: 4px;
        line-height: 25px;
    }
    .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        font-weight: bold;
        margin-top: 48px;
    }
    .advancedImage {
        height: 100%;
        width: 100%;
        max-width: 100%;
        min-height: 445px;
        margin-bottom: -24px;
        max-height: 100%;
    }
    /* .hidden-md-and-up {
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -13px;
    }
    .hidden-md-and-up img{
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -17px;
    } */
    @media only screen and (max-width: 768px) {
        .whiteHeader {
            margin-bottom: 0;
            margin-top: 0;
        }
        .whiteLine {
            margin-top: 0;
        }
    }
    @media only screen and (max-width: 640px) {
        .no-webp .slide-1 {
            background-image: url(../../assets/images/home/advancedSlideAlt.jpg)
        }
        .no-webp .slide-2 {
            background-image: url(../../assets/images/home/tabletSlideAlt.jpg)
        }
        .no-webp .slide-3 {
            background-image: url(../../assets/images/home/lazerSlideAlt.jpg)
        }
        .no-webp .slide-4 {
            background-image: url(../../assets/images/home/cuttingLineSlideAlt.jpg)
        }
        .no-webp .slide-5 {
            background-image: url(../../assets/images/home/lastSlideAlt.jpg)
        }
        .no-webp #bannerHolder {
            background: url(../../assets/images/home/lazerSlideAlt.jpg);
            min-height: 496px;
            /* background-size: contain; */
        }
        .webp .slide-1 {
            background-image: url(../../assets/images/home/advancedSlideAlt.webp)
        }
        .webp .slide-2 {
            background-image: url(../../assets/images/home/tabletSlideAlt.webp)
        }
        .webp .slide-3 {
            background-image: url(../../assets/images/home/lazerSlideAlt.webp)
        }
        .webp .slide-4 {
            background-image: url(../../assets/images/home/cuttingLineSlideAlt.webp)
        }
        .webp .slide-5 {
            background-image: url(../../assets/images/home/lastSlideAlt.webp)
        }
        .webp #bannerHolder {
            background: url(../../assets/images/home/lazerSlideAlt.webp);
            min-height: 496px;
            /* background-size: contain; */
        }
    }
</style>
