<template>
	<div id="whoWeAre" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
        <v-container grid-list-xl id="whoWeAreContainer">
            <v-layout row wrap>
				<white style="padding-top: 50px;">
                    <v-layout row wrap>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <v-flex lg12 md12 sm12 xs12 align-center>
                                    <!-- <img src="../../assets/images/whoWeAre/reconeng.png" /> -->
                                    <picture>
                                        <source srcset="../../assets/images/whoWeAre/reconeng.webp" type="image/webp">
                                        <source srcset="../../assets/images/whoWeAre/reconeng.png" type="image/png"> 
                                        <img src="../../assets/images/whoWeAre/reconeng.png" alt="Reconeng">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg12 md12 sm12 xs12 align-center>
                                    <!-- <img src="../../assets/images/whoWeAre/innovation.png" /> -->
                                    <picture>
                                        <source srcset="../../assets/images/whoWeAre/innovation.webp" type="image/webp">
                                        <source srcset="../../assets/images/whoWeAre/innovation.png" type="image/png"> 
                                        <img src="../../assets/images/whoWeAre/innovation.png" alt="Reconeng Innovation">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg5 md5 sm12 xs12>
                                    <!-- <img style="cursor: pointer;" @click="openBrochureModal()" src="../../assets/images/global/downloadReconengBrochure.png" /> -->
                                    <picture style="cursor: pointer;" @click="openBrochureModal()">
                                        <source srcset="../../assets/images/global/downloadReconengBrochure.webp" type="image/webp">
                                        <source srcset="../../assets/images/global/downloadReconengBrochure.png" type="image/png"> 
                                        <img src="../../assets/images/global/downloadReconengBrochure.png" alt="Reconeng Brochure">
                                    </picture>
                                </v-flex>
                                <v-flex lg2 md2 sm12 xs12 style="max-height: 100px;">
                                    <!-- <img src="../../assets/images/global/auMade.png" /> -->
                                    <picture>
                                        <source srcset="../../assets/images/global/auMade.webp" type="image/webp">
                                        <source srcset="../../assets/images/global/auMade.png" type="image/png"> 
                                        <img src="../../assets/images/global/auMade.png" alt="Reconeng">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg3 md2 sm12 xs12 align-center>
                                </v-flex>
                                <v-flex lg6 md8 sm12 xs12>
                                    <p class="descriptionDark">
                                        ProfiStop is a subsidiary or ReconEng Industrial Engineering, a multi-disciplined engineering group providing design, automation and project management services for large multinational and Government Clients.
                                        <br><br>
                                        ProfiStop is a result of many linear positioning solutions implemented over the years during which time we realised that most Australian and other manufacturers in the 21. Century cut the same way as at the dawn of circular saws - relaying on manual labour in a process that is dull and intensive on mind and body. We thought there should be simple positioning tools to assist you to cut faster, more accurate, and automatically; as well as tools to handle large amounts of cutting-associated data for you. We also thought these should not be the domain of big businesses, but small and one-man manufacturers alike.
                                        <br><br>
                                        Ten years later, we think we have the simple solutions to take cutting into 21. Century.
                                    </p>
                                </v-flex>
                                <v-flex lg3 md3 sm12 xs12>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </white>
			</v-layout>
		</v-container>
        <brochure-modal></brochure-modal>
        <p id="brochureType" style="display: none;">Reconeng</p>
	</div>
</template>
<script>
    import Blue from '../helper_components/Blue.vue';
    import White from '../helper_components/White.vue';
    import BrochureModal from '../helper_components/BrochureModal.vue';
	export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'ProfiStop',
            // all titles will be injected into this template
            titleTemplate: '%s | Who We Are',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: 'Leading measuring stop and automatic saw solutions for your cutting production. Fast payback and ROI, game-changing efficiency.'
                }
            ],
        },
		components: {
			'blue': Blue,
			'white': White,
            'brochure-modal': BrochureModal
        },
        methods: {
            swipeLeft() {
                this.$router.push('/contact')
            },
            swipeRight() {
                this.$router.push('/news')
            },
            openBrochureModal() {
                document.getElementById('brochureTrigger').click();
            }
        }
    }
</script>
<style scoped>
    .backPanel {
        min-height: 250px;
        margin-top: 22px;
        padding-bottom: 30px;
        padding-top: 30px;
        background-color: rgba(255,255,255,0.1);
        overflow: hidden;
    }
    ul {
        list-style: none;
        list-style: none;
        text-align: left;
        margin: 0px;
        padding: 0 0 0 5%;;
    }
    .grid-list-xl {
        margin-right: auto!important;
    }
    #whoWeAre {
        padding: 0;
    }
    #whoWeAreTitle {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 0 0 15px 50px;
        max-width: 444px;
        margin: 0 0 0 15%;
        text-align: left;
        /* background: url(../../assets/images/whoWeAre/whoWeAreIco.png) no-repeat -7px 0; */
        font-weight: bold;
    }
    #whoWeAreContainer {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    .whiteHeader {
        color: #fff;
        font-size: 16px;
        line-height: 1.5rem;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        font-weight: bold;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 0;
    }
    .descriptionWhite {
        color: #fff;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        padding-left: 20%;
        padding-right: 20%;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
        margin-top: 0;
    }
    .descriptionDark {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        margin: 0 0 150px 0;
        line-height: 25px;
        padding-top: 50px;
    }
    .darkHeader {
        color: #2f2f2f;
        font-size: 16px;
        line-height: 1.5rem;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        font-weight: bold;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 0;
    }
    .hidden-md-and-up {
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -17px;
    }
</style>