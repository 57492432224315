<template>
	<div id="advanced" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
        <v-container grid-list-xl id="mainadvancedHolder">
			<v-layout row wrap>
                <div class="hidden-sm-and-down" style="z-index: 1000; margin-left: 1%; position: fixed; left: 0; top: 50%; transform: translate(0%, -50%); font-size: 35px;">
                    <router-link :to="{ path: '/products/basic' }" style="color: #222;">
                        <font-awesome-icon :icon="leftArrowIcon" />
                    </router-link>
                </div>
                <div class="hidden-sm-and-down" style="z-index: 1000; margin-right: 1%; position: fixed; right: 0; top: 50%; transform: translate(0%, -50%); font-size: 35px;">
                    <router-link :to="{ path: '/products/automatic-saws' }" style="color: #222;">
                        <font-awesome-icon :icon="rightArrowIcon" />
                    </router-link>
                </div>
                <white>
                    <v-layout row wrap class="advancedImage">
                        <v-container grid-list-xl id="advancedHolder">
                            <v-layout row wrap>
                                <!-- <v-flex md2>
                                </v-flex> -->
                                <v-flex md6 xs12>
                                    <div class="darkLine"></div>
                                    <h4 class="darkHeader">ProfiStop<sup>&reg;</sup> Advanced <br>Saw Measuring Stop</h4>
                                </v-flex>
                                <v-flex md6 xs12 >
                                    <!-- <img style="cursor: pointer;" @click="openBrochureModal()" src="../../../assets/images/global/downloadProfistopBrochure.png" id="downloadProfistopBrochure"/> -->
                                    <picture style="cursor: pointer;" @click="openBrochureModal()">
                                        <source srcset="../../../assets/images/global/downloadProfistopBrochure.webp" type="image/webp">
                                        <source srcset="../../../assets/images/global/downloadProfistopBrochure.png" type="image/png"> 
                                        <img src="../../../assets/images/global/downloadProfistopBrochure.png" alt="PS Brochure">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <!-- <v-flex md2>
                                </v-flex> -->
                                <v-flex md6 xs12>
                                    <p class="descriptionDark">
                                        ProfiStop is a simple to use measuring stop solution for your saws 
                                        with advanced productivity functions to speed up and streamline your production. 
                                        It is designed to give you industry 4.0 cutting production automation while 
                                        remaining a simple to use product.
                                    </p>
                                    <ul class="text-left" style="padding-left: 20px; padding-bottom: 100px;">
                                        <li v-for="item in advanceditems" :key="item.text">
                                            <div :style="{ 'background-image': 'url(' + item.image + ')', paddingLeft: '90px' }">
                                                <p style="padding-bottom: 27px; padding-top: 27px; display: inline-block; margin-bottom: 0; font-size: 16px;">
                                                    {{ item.text }}
                                                </p>
                                                <br>
                                            </div>
                                            <!-- <img :src = item.image style="bottom: 0;"/>  -->
                                        </li>
                                    </ul>
                                </v-flex>
                                <v-flex md6 xs12 class="hidden-md-and-down" id="imageHolder">
                                    <!-- <img src="../../../assets/images/products/advanced/advancedImage.png" style="bottom: -120px; right: 0; position: absolute"/> -->
                                </v-flex>
                                <v-flex md4 xs12 class="hidden-md-and-up">
                                    <!-- <img src="../../../assets/images/products/advanced/advancedImage.png"/> -->
                                    <picture>
                                        <source srcset="../../../assets/images/products/advanced/advancedImage.webp" type="image/webp">
                                        <source srcset="../../../assets/images/products/advanced/advancedImage.png" type="image/png"> 
                                        <img src="../../../assets/images/products/advanced/advancedImage.png" alt="PS Advanced">
                                    </picture>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </white>
			</v-layout>
            <v-layout row wrap>
                <blue>
                    <v-layout row wrap>
                        <v-flex md1 xs12></v-flex>
                        <v-flex md5 xs12 sm12 style="margin-top: 30px!important;">
                            <iframe src="https://www.youtube.com/embed/jyZU-n1hmKA?rel=0" frameborder="0" allowfullscreen style="width: 100%; height: 360px;"></iframe>
                        </v-flex>
                        <v-flex md5 xs12 sm12>
                            <h4 style="color: #fff; margin-top: 30px!important;">
                                <b>Intro to ProfiStop<sup>&reg;</sup></b><br>
                                Simple Length Stop<br>
                                Solution for your Saws
                            </h4>
                            <button class="findOutMore" @click="openContactModal()">Find Out More</button>
                        </v-flex>
                    </v-layout>
                </blue>
            </v-layout>
            <v-layout row wrap>
                <white>
                    <tables></tables>
                </white>
            </v-layout>
		</v-container>
        <contact-modal></contact-modal>
        <brochure-modal></brochure-modal>
        <p id="brochureType" style="display: none;">Profistop</p>
        <demo-modal style="position: fixed!important; right: 1%!important; bottom: 1%!important; max-width: 200px!important;"></demo-modal>
        <v-btn id="fabButton" fab dark fixed
                bottom
                right 
                @click="openDemoModal()">+
        </v-btn>
	</div>
</template>
<script>
    import Blue from '../../helper_components/Blue.vue';
    import White from '../../helper_components/White.vue';
    import Tables from '../../helper_components/Tables.vue';
    import ProductsDemoModal from '../../helper_components/ProductsDemoModal.vue';
    import ContactModal from '../../helper_components/ContactModal.vue';
    import BrochureModal from '../../helper_components/BrochureModal.vue';
    import FontAwesomeIcon from '@fortawesome/vue-fontawesome';
    import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/fontawesome-free-solid';
	export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'ProfiStop',
            // all titles will be injected into this template
            titleTemplate: '%s | Advanced',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: 'Do you cut aluminium, wood or steel? ProfiStop is a simple to use, high-accuracy measuring stop, capable of transforming your saw into a high-productivity workstation. Are you ready to automate your business? Click here now.'
                }
            ],
        },
		components: {
			'blue': Blue,
            'white': White,
            'tables': Tables,
            'contact-modal': ContactModal,
            'brochure-modal': BrochureModal,
            'font-awesome-icon': FontAwesomeIcon,
            'demo-modal': ProductsDemoModal
        },
        data() { 
            return {
                advanceditems: [
                    { text: 'Automatic measuring stop and feeder for your saws.', image: require('../../../assets/images/products/advanced/advanced1.png') },
                    { text: '40 / 100/ 600 kg (88 / 220 / 1320 lbs) piece pushing capacity.', image: require('../../../assets/images/products/advanced/advanced2.png') },
                    { text: '±0.10 mm positioning repeatability.', image: require('../../../assets/images/products/advanced/advanced3.png') },
                    { text: 'Simple to use colour touch-screen interface.', image: require('../../../assets/images/products/advanced/advanced4.png') },
                    { text: 'Automated and streamlined jobs management system.', image: require('../../../assets/images/products/advanced/advanced5.png') },
                    { text: 'Automatic part label printing and bar-code jobs input.', image: require('../../../assets/images/products/advanced/advanced6.png') },
                    { text: 'OptiCut1D cutting production management system integrated.', image: require('../../../assets/images/products/advanced/advanced7.png') }
                ]
            }
        },
        methods: {
            swipeLeft() {
                this.$router.push('/products/automatic-saws')
            },
            swipeRight() {
                this.$router.push('/products/basic')
            },
            openContactModal() {
                document.getElementById('contactTrigger').click();
            },
            openBrochureModal() {
                document.getElementById('brochureTrigger').click();
            },
            openDemoModal() {
                document.getElementById('demoModalTrigger').click();
            }
        },
        computed: {
            leftArrowIcon () {
                return faArrowAltCircleLeft
            },
            rightArrowIcon () {
                return faArrowAltCircleRight
            }
        }
    }
</script>
<style scoped>
    #fabButton {
        border-radius: 50px;
        width: 50px!important;
        height: 50px;
        font-size: 20px;
        max-width: 50px!important;
        position: fixed!important;
        right: 55px!important;
        bottom: 1%!important;
        background-color: orange;
    }
    .no-webp #imageHolder {
        background: url(../../../assets/images/products/advanced/advancedImage.png);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        bottom: 25px;
        right: -8%;
    }
    .webp #imageHolder {
        background: url(../../../assets/images/products/advanced/advancedImage.webp);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        bottom: 25px;
        right: -8%;
    }
    li {
        list-style: none;
        text-align: left;
        padding-left: 0px;
        display: block;
    }
    .darkLine {
        width: 120px;
        height: 3px;
        background-color: #222;
        margin-bottom: 16px;
    }
    #advancedHolder {
        padding-bottom: 0;
        margin-bottom: -13px;
    }
    #mainadvancedHolder {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    #advancedBrochure {
        margin-top: 92px;
    }
    .no-webp .whiteHeader {
        color: #fff;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 4px 0 15px 50px;
        max-width: 444px;
        margin: 0;
        text-align: left;
        background: url(../../../assets/images/global/iconWhite.png) no-repeat 0 0;
        font-weight: bold;
        margin-bottom: 48px;
    }
    .webp .whiteHeader {
        color: #fff;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 4px 0 15px 50px;
        max-width: 444px;
        margin: 0;
        text-align: left;
        background: url(../../../assets/images/global/iconWhite.webp) no-repeat 0 0;
        font-weight: bold;
        margin-bottom: 48px;
    }
    .descriptionWhite {
        color: #fff;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 0 20% 0 50px;
        /* padding-left: 50px; */
        margin: 0;
        margin-bottom: 50px;
        margin-top: 4px;
        line-height: 25px;
    }
    .descriptionDark {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 0;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
        padding-right: 50px;
    }
    .no-webp .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 4px 0 15px 55px;
        max-width: 370px;
        margin: 0;
        margin-right: 5px;
        background: url(../../../assets/images/global/iconGray.png) no-repeat center left;
        font-weight: bold;
        margin-bottom: 48px;
        float: left;
    }
    .webp .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 4px 0 15px 55px;
        max-width: 370px;
        margin: 0;
        margin-right: 5px;
        background: url(../../../assets/images/global/iconGray.webp) no-repeat center left;
        font-weight: bold;
        margin-bottom: 48px;
        float: left;
    }
    .advancedImage {
        height: 100%;
        width: 100%;
        max-width: 100%;
        min-height: 445px;
        margin-bottom: -24px;
        max-height: 100%;
    }
    .hidden-md-and-up {
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -13px;
    }
    .hidden-md-and-up img{
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -17px;
    }
    @media only screen and (max-width: 768px) {
        .advancedImage {
            background: none;
            min-height: 0;
        }
        #advancedBrochure {
            margin-top: 0;
        }
        .whiteHeader {
            margin-bottom: 0;
            margin-top: 0;
        }
        .whiteLine {
            margin-top: 0;
        }
    }
</style>
