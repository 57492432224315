<template>
    <div class="newsArticle" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
        <white>
            <div v-if="error" class="error">
                {{ error }}
            </div>
            <v-container grid-list-xl>
                <v-layout row wrap style="margin-bottom: 50px;">
                    <v-flex lg6 md6>
                        <div class="titleLine"></div>
                        <h1 id="pageTitle">
                            News &amp; Updates
                        </h1>
                    </v-flex>
                    <v-flex md5 xs12 style="float: right;">
                        <!-- <img style="cursor: pointer; float: right;" @click="openBrochureModal()" src="../../assets/images/global/downloadProfistopBrochure.png" /> -->
                        <picture style="cursor: pointer; float: right;" @click="openBrochureModal()" >
                            <source srcset="../../assets/images/global/downloadProfistopBrochure.webp" type="image/webp">
                            <source srcset="../../assets/images/global/downloadProfistopBrochure.png" type="image/png"> 
                            <img src="../../assets/images/global/downloadProfistopBrochure.png" alt="PS Brochure">
                        </picture>
                    </v-flex>
                </v-layout>
                <v-layout row wrap class="loading" v-if="loading">
                    <v-flex lg12 md12 sm12 xs12>
                        <half-circle-spinner style="transform: translate(-50%, 0); position: relative; left: 50%; margin-top: 100px; margin-bottom: 100px;"
                            :animation-duration="1000"
                            :size="150"
                            :color="'#0066cc'"
                        />
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-layout row wrap>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <v-flex lg12 md12 sm12 xs12>
                                    <ul class="text-left">
                                        <li v-for="article in newsArticle" :key="article.articleLink">
                                            <v-layout row wrap>
                                                <v-flex lg4 md4 sm12 xs12>
                                                    <span style="display: inline-block" v-html= article.articleImage></span>
                                                </v-flex>
                                                <v-flex lg8 md8 sm12 xs12 style="text-align: left;">
                                                    <div style="display: inline-flex;">
                                                        <p class="articleTitle blueTitle" v-if="article.blueTitle!=''" v-html=article.blueTitle></p>
                                                        <p class="articleTitle" v-html="article.title + ' ' + article.postDate"></p>
                                                    </div>
                                                    <p class="articleText" v-if="article.hasVideo == 1" v-html="article.title + ' ' + article.postDate"></p>
                                                    <p class="articleText" v-if="article.hasVideo == 0" v-html="article.paragraph.substring(0, 100) + '...'"></p>
                                                    <router-link :to="{ path: '/news/article/' + article.articleLink }">
                                                        Read More
                                                    </router-link>
                                                </v-flex>
                                            </v-layout>
                                            <!-- <span v-html= article.secondImage></span> -->
                                        </li>
                                    </ul>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </v-layout>
            </v-container>
        </white>
        <brochure-modal></brochure-modal>
        <p id="brochureType" style="display: none;">Profistop</p>
    </div>
</template>
<script>
import axios from 'axios';
import White from '../helper_components/White.vue';
import BrochureModal from '../helper_components/BrochureModal.vue';
import { HalfCircleSpinner } from 'epic-spinners';
export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'ProfiStop',
        // all titles will be injected into this template
        titleTemplate: '%s | News',
        meta: [
            {
                vmid: 'description',
                name: 'description',
                content: 'Leading measuring stop and automatic saw solutions for your cutting production. Fast payback and ROI, game-changing efficiency.'
            }
        ],
    },
    components: {
        'white': White,
        'brochure-modal': BrochureModal,
        'half-circle-spinner': HalfCircleSpinner
    },
    data () {
        return {
            loading: false,
            newsArticle: [],
            error: null
        }
    },
    created () {
        // fetch the data when the view is created and the data is
        // already being observed
        this.fetchData()
    },
    watch: {
        // call again the method if the route changes
        '$route': 'fetchData'
    },
    methods: {
        swipeLeft() {
            this.$router.push('/who-we-are')
        },
        swipeRight() {
            this.$router.push('/why-profistop')
        },
        fetchData () {
            this.error = null;
            this.loading = true;
            var that = this;
            axios.post(`https://profistop.com/listNews.php`, {
            })
            .then(function (response) {
                that.newsArticle = response.data;
                that.loading = false;
            })
            .catch(e => {
                console.log(e);
            });
        },
        openBrochureModal() {
            document.getElementById('brochureTrigger').click();
        }
    }
}
</script>
<style scoped>
    li {
        background-color: rgba(16,16,16,0.1);
        max-width: 950px;
        padding: 0;
        margin-bottom: 42px;
        /* max-height: 184px; */
        list-style: none;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .blueTitle {
        color: #0066cc!important;
        margin: 26px 0 0 0!important;
    }
    .articleTitle {
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #585858;
        text-align: left;
        margin: 26px 25px 18px 15px;
        display: inline-block;
    }
    .articleText {
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        font-size: 16px;
        color: #585858;
        text-align: left;
        margin: 0 25px 20px 15px;
    }
    .no-webp #pageTitle {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 0 0 15px 50px;
        max-width: 444px;
        margin: 0 0 0 15%;
        text-align: left;
        background: url(../../assets/images/news/newsIco.png) no-repeat 0 0;
        font-weight: bold;
    }
    .webp #pageTitle {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 0 0 15px 50px;
        max-width: 444px;
        margin: 0 0 0 15%;
        text-align: left;
        background: url(../../assets/images/news/newsIco.webp) no-repeat 0 0;
        font-weight: bold;
    }
</style>