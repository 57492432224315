<template>
    <div class="newsArticle">
        <white>
            <div v-if="error" class="error">
                {{ error }}
            </div>
            <v-container grid-list-xl>
                <v-layout row wrap style="margin-bottom: 50px;">
                    <v-flex lg6 md6>
                        <div class="titleLine"></div>
                        <h1 id="pageTitle">
                            News &amp; Updates
                        </h1>
                    </v-flex>
                    <v-flex md5 xs12 style="float: right;">
                        <!-- <img style="cursor: pointer; float: right;" @click="openBrochureModal()" src="../../assets/images/global/downloadProfistopBrochure.png" /> -->
                        <picture style="cursor: pointer; float: right;" @click="openBrochureModal()">
                            <source srcset="../../assets/images/global/downloadProfistopBrochure.webp" type="image/webp">
                            <source srcset="../../assets/images/global/downloadProfistopBrochure.png" type="image/png"> 
                            <img src="../../assets/images/global/downloadProfistopBrochure.png" alt="PS Brochure">
                        </picture>
                    </v-flex>
                </v-layout>
                <v-layout row wrap class="loading" v-if="loading">
                    <v-flex lg12 md12 sm12 xs12>
                        <half-circle-spinner style="transform: translate(-50%, 0); position: relative; left: 50%; margin-top: 100px; margin-bottom: 100px;"
                            :animation-duration="1000"
                            :size="150"
                            :color="'#0066cc'"
                        />
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-layout row wrap v-if="newsArticle">
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <v-flex lg12 md12 sm12 xs12 id="articleHolder">
                                    <v-layout row wrap style="text-align: left;">
                                        <v-flex lg12 md12 sm12 xs12 v-if="newsArticle[0].hasVideo == 1"> 
                                            <p class="articleTitle blueTitle" v-if="newsArticle[0].blueTitle!=''" v-html=newsArticle[0].blueTitle></p>
                                            <p class="articleTitle" v-html=newsArticle[0].title></p>
                                            <p>{{ newsArticle[0].postDate }}</p>
                                            <p class="articleText" style="text-align: center;" v-html=newsArticle[0].paragraph></p>
                                            <br><br>
                                            <p class="articleText">
                                                ProfiFeed Technologies
                                                <br><br>
                                                P 1300 776 700<br>
                                                E info@profistop.com.au
                                                <br><br>
                                                47 Healey Road, <br>
                                                Dandenong South, <br>
                                                VIC 3175, Australia
                                            </p>
                                        </v-flex>
                                        <v-flex lg8 md8 sm12 xs12 v-if="newsArticle[0].hasVideo == 0"> 
                                            <p class="articleTitle blueTitle" v-if="newsArticle[0].blueTitle!=''" v-html=newsArticle[0].blueTitle></p>
                                            <p class="articleTitle" v-html=newsArticle[0].title></p>
                                            <p>{{ newsArticle[0].postDate }}</p>
                                            <p class="articleText" v-html=newsArticle[0].paragraph></p>
                                            <br><br>
                                            <p class="articleText">
                                                ProfiFeed Technologies
                                                <br><br>
                                                P 1300 776 700<br>
                                                E info@profistop.com.au
                                                <br><br>
                                                47 Healey Road, <br>
                                                Dandenong South, <br>
                                                VIC 3175, Australia
                                            </p>
                                        </v-flex>
                                        <v-flex lg4 md4 sm12 xs12 v-if="newsArticle[0].hasVideo == 0">
                                            <v-layout row wrap>
                                                <v-flex lg12 md12 sm12 xs12 style="text-align: center;">
                                                    <span v-html= newsArticle[0].firstImage></span>
                                                </v-flex>
                                                <v-flex lg12 md12 sm12 xs12 style="text-align: center;">
                                                    <span v-html= newsArticle[0].secondImage></span>
                                                </v-flex>
                                                <v-flex lg12 md12 sm12 xs12>
                                                    <ul class="shareLinks">
                                                        <li class="clearfix">Share:</li><br>
                                                        <li>
                                                            <a :href="facebookShare" target="_blank" title="Share on Facebook">
                                                                <img src="../../assets/images/global/facebook.png" alt="Facebook Share">
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a :href="linkedInShare" target="_blank" title="Share on Linkedin">
                                                                <img src="../../assets/images/global/linkedin.png" alt="Linked Share">
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a :href="twitterShare" target="_blank" title="Share on Twitter">
                                                                <img src="../../assets/images/global/twitter.png" alt="Twitter Share">
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a :href="gplusShare" target="_blank" title="Share on Google+">
                                                                <img src="../../assets/images/global/gPlus.png" alt="Google Plus Share">
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </v-layout>
            </v-container>
        </white>
        <brochure-modal></brochure-modal>
        <p id="brochureType" style="display: none;">Profistop</p>
    </div>
</template>
<script>
import axios from 'axios';
import White from './White.vue';
import BrochureModal from '../helper_components/BrochureModal.vue';
import { HalfCircleSpinner } from 'epic-spinners';
export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'ProfiStop',
        // all titles will be injected into this template
        titleTemplate: '%s | News',
        meta: [
            {
                vmid: 'description',
                name: 'description',
                content: 'Leading measuring stop and automatic saw solutions for your cutting production. Fast payback and ROI, game-changing efficiency.'
            }
        ],
    },
    components: {
        'white': White,
        'brochure-modal': BrochureModal,
        'half-circle-spinner': HalfCircleSpinner
    },
    data () {
        return {
            loading: false,
            newsArticle: [],
            error: null,
            facebookShare: "",
            linkedInShare: "",
            twitterShare: "",
            gplusShare: ""
        }
    },
    created () {
        // fetch the data when the view is created and the data is
        // already being observed
        this.fetchData()
    },
    watch: {
        // call again the method if the route changes
        '$route': 'fetchData'
    },
    methods: {
        fetchData () {
            this.error = this.newsArticle = null;
            this.loading = true;
            var that = this;
            var axiosData = JSON.stringify({
                articleLink: this.$route.params.id
            });
            this.facebookShare = "https://www.facebook.com/sharer/sharer.php?u=profistop.com/news/" + this.$route.params.id;
            this.linkedInShare = "https://www.linkedin.com/shareArticle?mini=true&amp;url=profistop.com/news/" + this.$route.params.id + "/&amp;title=ProfiStop+News+Article&amp;summary=&amp;source=";
            this.twitterShare = "https://twitter.com/home?status=profistop.com/news/" + this.$route.params.id + "//nProfiStop+News+Article";
            this.gplusShare = "https://plus.google.com/share?url=profistop.com/news/" + this.$route.params.id;
            axios.post(`https://profistop.com/readArticle.php`, {
                axiosData
            })
            .then(function (response) {
                that.newsArticle = response.data;
                that.loading = false;
            })
            .catch(e => {
                console.log(e);
            });
        },
        openBrochureModal() {
            document.getElementById('brochureTrigger').click();
        }
    }
}
</script>
<style scoped>
    /* li {
        background-color: rgba(16,16,16,0.1);
        max-width: 950px;
        padding: 0;
        margin-bottom: 42px;
        max-height: 184px;
        list-style: none;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
    } */
    li {
        display: inline-block;
    }
    .blueTitle {
        color: #0066cc!important;
        margin: 26px 0 0 0!important;
    }
    .articleTitle {
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        font-size: 22px;
        font-weight: bold;
        color: #585858;
        text-align: left;
        margin: 38px 0px 10px 5px;
        display: inline-block;
    }
    .articleText {
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        font-size: 16px;
        color: #585858;
        text-align: left;
        margin: 0 0 20px 15px;
        text-align: justify;
    }
    .no-webp #pageTitle {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 0 0 15px 50px;
        max-width: 444px;
        margin: 0 0 0 15%;
        text-align: left;
        background: url(../../assets/images/news/newsIco.png) no-repeat 0 0;
        font-weight: bold;
    }
    .webp #pageTitle {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 0 0 15px 50px;
        max-width: 444px;
        margin: 0 0 0 15%;
        text-align: left;
        background: url(../../assets/images/news/newsIco.webp) no-repeat 0 0;
        font-weight: bold;
    }
    #articleHolder {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: rgba(16,16,16,0.1);
        max-width: 950px;
        padding: 25px;
        margin-bottom: 42px;
    }
    .shareLinks {
        /* position: absolute; */
        right: 142px;
        bottom: 60px;
        overflow: hidden;
        clear: both;
        margin: 0;
        padding: 0;
        list-style: none;
    }
</style>