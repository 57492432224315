<template>
    <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="500px">
            <v-btn color="primary" dark slot="activator" class="contactModalTrigger" id="brochureTrigger">Download Brochure</v-btn>
            <v-card class="backImage">
                <v-btn icon @click.native="dialog = false" light>
                    <v-icon>close</v-icon>
                </v-btn>
                <v-card-title>
                    <span class="headline" style="width: 40%;">
                        <!-- <img src="../../assets/images/global/lightBulb.png" style="height: 50px; position: relative; left: 50%;"/> -->
                        <picture>
                            <source srcset="../../assets/images/global/lightBulb.webp" type="image/webp">
                            <source srcset="../../assets/images/global/lightBulb.png" type="image/png"> 
                            <img src="../../assets/images/global/lightBulb.png" alt="Light">
                        </picture>
                        <br>
                    </span>
                    <span class="headline" style="text-align: right; width: 60%;">ProfiStop&reg;
                        <br>
                        Brochure / Enquire Form
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout row wrap>
                            <v-flex xs12 sm12 md6>
                                <p style="font-size: 16px; text-align: justify;">
                                    We hear every day about our machine transforming a dull and labour-intensive job, into one where everyone clamours to be at the saw. 
                                    <br><br>
                                    ProfiStop&reg; in your factory will:
                                    <br><br>
                                    <ul>
                                        <li>Energise your team;</li>
                                        <li>Make the work faster and more accurate;</li>
                                        <li>Make the work easier and more enjoyable;</li>
                                        <li>Return you transformational productivity and efficiency savings.</li>
                                    </ul>
                                    <br><br>
                                    There are few, if any, investments you and your staff can get excited about like this.
                                </p>
                            </v-flex>
                            <v-flex xs12 sm12 md6>
                                <v-form v-model="valid" ref="brochureForm">
                                    <v-flex xs12 sm12 md12>
                                        <v-text-field label="Your Name" hint="Enter your full name" v-model="name" :rules="nameRules" required></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12>
                                        <v-text-field label="Your Email address" hint="Enter your email" v-model="email" :rules="emailRules" required></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12>
                                        <v-text-field label="Company Name" hint="Enter your company name" v-model="company" :rules="companyRules" required></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12>
                                        <v-text-field label="Your Phone Number" hint="Enter your phone number" v-model="phone" :rules="phoneRules" required></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12>
                                        <v-text-field label="Your Country/State" hint="Enter your country" v-model="country"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12>
                                        <v-text-field multi-line label="Type Your Message Here" v-model="message"></v-text-field>
                                    </v-flex>
                                </v-form>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" dark @click.native="dialog = false">Cancel</v-btn>
                    <vue-recaptcha @verify="afterRecaptchaVerify" sitekey="6LcX4UoUAAAAAI8o465brHjLeIPhq3c19qD5auXu" size="invisible" ref="invisibleRecaptcha">
                        <v-btn
                            color="primary" dark
                            @click.native="dialog = false;"
                            :disabled="!valid">
                            Download (PDF)
                        </v-btn>
                    </vue-recaptcha>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-layout row justify-center>
            <v-dialog v-model="dialogInfo" persistent max-width="290">
            <v-btn color="primary" dark slot="activator" id="brochureDownloadInfoActivator" style="display: none;">Brochure Download Info</v-btn>
            <v-card>
                <v-card-title class="headline" id="infoTitle"></v-card-title>
                <v-card-text id="infoText"></v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat @click.native="dialogInfo = false">OK</v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-layout>
    </v-layout>
</template>
<script>
    import VueRecaptcha from 'vue-recaptcha';
    import axios from 'axios';
    export default {
        components: { VueRecaptcha },
        data: () => ({
            dialog: false,
            dialogInfo: false,
            valid: false,
            name: '',
            nameRules: [
                v => !!v || 'Name is required'
            ],
            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],
            phone: '',
            phoneRules: [
                v=> !!v || 'Phone is required',
                v=> /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) || 'Phone number must be valid'
            ],
            company: '',
            companyRules: [
                v=> !!v || 'Company is required'
            ],
            country: '',
            message: '',
            errors: []
        }),
        methods: {
            handleForm(recaptchaResponse) {
                if (this.$refs.brochureForm.validate()) {
                    var userFormData = {};
                    userFormData = {
                        formType: 'brochure',
                        name: this.name,
                        email: this.email,
                        phone: this.phone,
                        company: this.company,
                        country: this.country,
                        message: this.message,
                        grec: recaptchaResponse,
                        brochureType: document.getElementById('brochureType').innerText
                    };
                    var brochureFormLocal = this.$refs.brochureForm;
                    var axiosData = JSON.stringify(userFormData);
                    axios.post(`https://profistop.com/newEmailScript.php`, {
                        axiosData
                    })
                    .then(function (response) {
                        brochureFormLocal.reset();
                        if(userFormData.brochureType == 'Profistop' && response.data.result) {
                            document.getElementById('infoTitle').innerHTML = "ProfiStop Brochure Download";
                            document.getElementById('infoText').innerHTML = response.data.returnMessage;
                            document.getElementById('brochureDownloadInfoActivator').click();
                            setTimeout(() => {
                                axios({
                                    url: `https://profistop.com/profistop-brochure.php`,
                                    method: 'GET',
                                    responseType: 'blob'
                                })
                                .then((pdfResponse) => {
                                    console.log('brochure download started');
                                    const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', 'ProfiStop-Brochure.pdf');
                                    document.body.appendChild(link);
                                    link.click();
                                })
                                .catch(e => {
                                    console.log(e);
                                })
                            }, 5000);
                        } else if (userFormData.brochureType == 'Reconeng' && response.data.result) {
                            document.getElementById('infoTitle').innerHTML = "ReconENG Brochure Download";
                            document.getElementById('infoText').innerHTML = response.data.returnMessage;
                            document.getElementById('brochureDownloadInfoActivator').click();
                            setTimeout(() => {
                                axios({
                                    url: `https://profistop.com/reconeng-brochure.php`,
                                    method: 'GET',
                                    responseType: 'blob'
                                })
                                .then((pdfResponse) => {
                                    console.log('brochure download started');
                                    const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', 'ReconENG-Brochure.pdf');
                                    document.body.appendChild(link);
                                    link.click();
                                })
                                .catch(e => {
                                    console.log(e);
                                })
                            }, 5000);
                        } else if (userFormData.brochureType == 'Lazer' && response.data.result) {
                            document.getElementById('infoTitle').innerHTML = "ProfiStop Lazer Brochure Download";
                            document.getElementById('infoText').innerHTML = response.data.returnMessage;
                            document.getElementById('brochureDownloadInfoActivator').click();
                            setTimeout(() => {
                                axios({
                                    url: `https://profistop.com/lazer-brochure.php`,
                                    method: 'GET',
                                    responseType: 'blob'
                                })
                                .then((pdfResponse) => {
                                    console.log('brochure download started');
                                    const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', 'ProfiStop-Lazer-Brochure.pdf');
                                    document.body.appendChild(link);
                                    link.click();
                                })
                                .catch(e => {
                                    console.log(e);
                                })
                            }, 5000);
                        } else {
                            document.getElementById('infoTitle').innerHTML = "Brochure Download Error";
                            document.getElementById('infoText').innerHTML = response.data.returnMessage;
                            document.getElementById('brochureDownloadInfoActivator').click();
                        }
                    })
                    .catch(e => {
                        this.errors.push(e);
                    });
                }
            },
            afterRecaptchaVerify(recaptchaResponse) {
                this.handleForm(recaptchaResponse);
            }
        }
    }
</script>
<style scoped>
    .application .theme--dark.btn.btn--disabled:not(.btn--icon):not(.btn--flat) {
        background-color: hsla(0, 0%, 22%, 0.59)!important;
        color: white!important;
    }
    .contactModalTrigger {
        display: none;
    }
    .card__text {
        padding: 0 16px 0 16px;
    }
    ul {
        list-style-position: outside;
    }
    li {
        font-size: 16px;
        list-style: none;
        text-align: left;
    }
    li:before {
        content: '✔';   
        margin-left: -1em; margin-right: .100em;
        color: #20a8eb;
    }
    .no-webp .backImage {
        background: url(../../assets/images/backgrounds/white.jpg) top left no-repeat #fff;
        border-radius: 0px;
        border: none;
    }
    .webp .backImage {
        background: url(../../assets/images/backgrounds/white.webp) top left no-repeat #fff;
        border-radius: 0px;
        border: none;
    }
</style>