<template>
	<div id="cuttingLine" v-touch:swipe.right="swipeRight">
        <v-container grid-list-xl id="maincuttingLineHolder">
			<v-layout row wrap>
                <div class="hidden-sm-and-down" style="z-index: 1000; margin-left: 1%; position: fixed; left: 0; top: 50%; transform: translate(0%, -50%); font-size: 35px;">
                    <router-link :to="{ path: '/products/automatic-saws' }" style="color: #222;">
                        <font-awesome-icon :icon="leftArrowIcon" />
                    </router-link>
                </div>
                <white>
                    <v-layout row wrap class="cuttingLineImage">
                        <v-container grid-list-xl id="cuttingLineHolder">
                            <v-layout row wrap>
                                <!-- <v-flex md2>
                                </v-flex> -->
                                <v-flex md6 xs12>
                                    <div class="darkLine"></div>
                                    <h4 class="darkHeader">ProfiFeed<sup>&reg;</sup> <br>Automatic Cutting Lines</h4>
                                </v-flex>
                                <v-flex md6 xs12 >
                                    <!-- <img style="cursor: pointer;" @click="openBrochureModal()" src="../../../assets/images/global/downloadProfistopBrochure.png" id="downloadProfistopBrochure"/> -->
                                    <picture style="cursor: pointer;" @click="openBrochureModal()">
                                        <source srcset="../../../assets/images/global/downloadProfistopBrochure.webp" type="image/webp">
                                        <source srcset="../../../assets/images/global/downloadProfistopBrochure.png" type="image/png"> 
                                        <img src="../../../assets/images/global/downloadProfistopBrochure.png" alt="PS Brochure">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex md8 xs12>
                                    <p class="descriptionDark">
                                        ProfiFeed Cutting Line is an comprehensive cutting solution suitable for automatically processing medium and small <br>
                                        size aluminium or steel profiles with a continuous automatic loading, feeding, cutting-to-length, and completed parts collection. 
                                        <br><br>
                                        The system automatically processes most of profile shapes with no mechanical setup as well as collects parts automatically.<br>
                                        Proprietary jobbing system for simple management of many short-run parts.
                                        <br><br>
                                        Simple operation designed to be able to be learned in minutes and tasked for operation in seconds.
                                    </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex md4 xs12>
                                    <ul class="text-left" style="padding-left: 20px; padding-bottom: 100px;">
                                        <li v-for="item in cuttinglineitems" :key="item.text">
                                            <div :style="{ 'background-image': 'url(' + item.image + ')', paddingLeft: '90px' }">
                                                <p style="margin-bottom: 0; padding-bottom: 27px; padding-top: 27px; display: inline-block; font-size: 16px;">
                                                    {{ item.text }}
                                                </p>
                                                <br>
                                            </div>
                                            <!-- <img :src = item.image style="bottom: 0;"/>  -->
                                        </li>
                                    </ul>
                                </v-flex>
                                <v-flex md8 xs12 class="hidden-md-and-down" id="imageHolder">
                                </v-flex>
                                <v-flex md6 xs12 class="hidden-md-and-up">
                                    <!-- <img src="../../../assets/images/products/cutting-line/cuttingLineTop.png"/> -->
                                    <picture>
                                        <source srcset="../../../assets/images/products/cutting-line/cuttingLineTop.webp" type="image/webp">
                                        <source srcset="../../../assets/images/products/cutting-line/cuttingLineTop.png" type="image/png"> 
                                        <img src="../../../assets/images/products/cutting-line/cuttingLineTop.png" alt="PS Cutting Line">
                                    </picture>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </white>
			</v-layout>
            <v-layout row wrap>
                <blue>
                    <v-container grid-list-xl>
                        <v-layout row wrap id="cuttingLineIllustrationsSecond">
                            <v-flex md2 xs12></v-flex>
                            <v-flex md4 xs12>
                                <!-- <img src="../../../assets/images/products/cutting-line/cuttingline7.png" /> -->
                                <picture>
                                    <source srcset="../../../assets/images/products/cutting-line/cuttingline7.webp" type="image/webp">
                                    <source srcset="../../../assets/images/products/cutting-line/cuttingline7.png" type="image/png"> 
                                    <img src="../../../assets/images/products/cutting-line/cuttingline7.png" alt="PS Cutting Line">
                                </picture>
                                <br>
                                <h4 class="whiteHeaderSecondary">
                                    Automatic Yield Optimisation
                                </h4>
                                <p class="descriptionWhiteSecondary">
                                    Get the benefit of reduced material wastage but without spending time managing stock. 
                                    Send the same cut list you use today to ProfiFeed Cutting Line. 
                                    The machine automatically measures any length of stock on it for 
                                    cutting and optimises the job list for maximum yield.
                                </p>
                            </v-flex>
                            <v-flex md4 xs12 >
                                <!-- <img src="../../../assets/images/products/cutting-line/cuttingline8.png" /> -->
                                <picture>
                                    <source srcset="../../../assets/images/products/cutting-line/cuttingline8.webp" type="image/webp">
                                    <source srcset="../../../assets/images/products/cutting-line/cuttingline8.png" type="image/png"> 
                                    <img src="../../../assets/images/products/cutting-line/cuttingline8.png" alt="PS Cutting Line">
                                </picture>
                                <br>
                                <h4 class="whiteHeaderSecondary">
                                    Automatic Label Printing
                                </h4>
                                <p class="descriptionWhiteSecondary">
                                    Sort out your many jobs by labelling them. ProfiFeed Cutting Line prints a job 
                                    label for each batch or part made including bar codes for downstream identification.
                                </p>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex md4 xs12></v-flex>
                            <v-flex md4 xs12>
                                <!-- <img src="../../../assets/images/products/cutting-line/cuttingline9.png" /> -->
                                <picture>
                                    <source srcset="../../../assets/images/products/cutting-line/cuttingline9.webp" type="image/webp">
                                    <source srcset="../../../assets/images/products/cutting-line/cuttingline9.png" type="image/png"> 
                                    <img src="../../../assets/images/products/cutting-line/cuttingline9.png" alt="PS Cutting Line">
                                </picture>
                                <br>
                                <h4 class="whiteHeaderSecondary">
                                    OptiCut-1D&trade;
                                </h4>
                                <p class="descriptionWhiteSecondary">
                                    Our cloud-based designs, job and material management solution seamlessly 
                                    integrated with ProfiFeed Cutting Line. Automatically group and optimise 
                                    your many daily jobs and send to the machine with a click of the mouse.
                                </p>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </blue>
            </v-layout>
            <v-layout row wrap>
                <white style="min-height: fit-content;">
                    <v-layout row wrap class="cuttingLineImage" style="padding-top: 50px;">
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <v-flex md12 xs12 align-center>
                                    <!-- <img src="../../../assets/images/products/cutting-line/cuttingLineBottom.png"/> -->
                                    <picture>
                                        <source srcset="../../../assets/images/products/cutting-line/cuttingLineBottom.webp" type="image/webp">
                                        <source srcset="../../../assets/images/products/cutting-line/cuttingLineBottom.png" type="image/png"> 
                                        <img src="../../../assets/images/products/cutting-line/cuttingLineBottom.png" alt="PS Cutting Line">
                                    </picture>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </white>
            </v-layout>
		</v-container>
        <brochure-modal></brochure-modal>
        <p id="brochureType" style="display: none;">Profistop</p>
        <demo-modal style="position: fixed!important; right: 1%!important; bottom: 1%!important; max-width: 200px!important;"></demo-modal>
        <v-btn id="fabButton" fab dark fixed
                bottom
                right 
                @click="openDemoModal()">+
        </v-btn>
	</div>
</template>
<script>
    import Blue from '../../helper_components/Blue.vue';
    import White from '../../helper_components/White.vue';
    import Tables from '../../helper_components/Tables.vue';
    import ProductsDemoModal from '../../helper_components/ProductsDemoModal.vue';
    import BrochureModal from '../../helper_components/BrochureModal.vue';
    import FontAwesomeIcon from '@fortawesome/vue-fontawesome';
    import { faArrowAltCircleLeft } from '@fortawesome/fontawesome-free-solid';
	export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'ProfiStop',
            // all titles will be injected into this template
            titleTemplate: '%s | Cutting Line',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: 'Your complete automated cutting solution for small to medium steel and aluminium profiles. Continuous unattended operation with automatic loading, cutting to length and cut parts collection.'
                }
            ],
        },
		components: {
			'blue': Blue,
            'white': White,
            'tables': Tables,
            'brochure-modal': BrochureModal,
			'font-awesome-icon': FontAwesomeIcon,
            'demo-modal': ProductsDemoModal
        },
        data() { 
            return {
                cuttinglineitems: [
                    { text: 'Comprehensive automatic cutting solution for steel, aluminium or timber.', image: require('../../../assets/images/products/cutting-line/cuttingline1.png') },
                    { text: 'Continuous loading, cutting-to-length and parts collection.', image: require('../../../assets/images/products/cutting-line/cuttingline2.png') },
                    { text: 'Up to 120 profiles automatic loading capacity (size dependent).', image: require('../../../assets/images/products/cutting-line/cuttingline3.png') },
                    { text: 'Automatic material yield optimisation and label printing.', image: require('../../../assets/images/products/cutting-line/cuttingline4.png') },
                    { text: 'Up to 2000 kg (4400 lbs) material loading capacity.', image: require('../../../assets/images/products/cutting-line/cuttingline5.png') },
                    { text: 'Up to 100 kg (220 lbs) material feeding capacity.', image: require('../../../assets/images/products/cutting-line/cuttingline6.png') }
                ]
            }
        },
        methods: {
            swipeRight() {
                this.$router.push('/products/automatic-saws')
            },
            openBrochureModal() {
                document.getElementById('brochureTrigger').click();
            },
            openDemoModal() {
                document.getElementById('demoModalTrigger').click();
            }
        },
        computed: {
            leftArrowIcon () {
                return faArrowAltCircleLeft
            }
        }
    }
</script>
<style scoped>
    #fabButton {
        border-radius: 50px;
        width: 50px!important;
        height: 50px;
        font-size: 20px;
        max-width: 50px!important;
        position: fixed!important;
        right: 55px!important;
        bottom: 1%!important;
        background-color: orange;
    }
    #faBottom {
        /* background: url(../../../assets/images/products/cutting-line/faSawBottom.png); */
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        /* bottom: 25px;
        right: -8%; */
    }
    .no-webp #imageHolder {
        background: url(../../../assets/images/products/cutting-line/cuttingLineTop.png);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        bottom: 25px;
        right: -8%;
    }
    .webp #imageHolder {
        background: url(../../../assets/images/products/cutting-line/cuttingLineTop.webp);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        bottom: 25px;
        right: -8%;
    }
    li {
        list-style: none;
        text-align: left;
        padding-left: 0px;
        display: block;
    }
    .darkLine {
        width: 120px;
        height: 3px;
        background-color: #222;
        margin-bottom: 16px;
    }
    #cuttingLineHolder {
        padding-bottom: 0;
        margin-bottom: -13px;
    }
    #maincuttingLineHolder {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    #cuttingLineBrochure {
        margin-top: 92px;
    }
    .no-webp .whiteHeader {
        color: #fff;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 4px 0 15px 50px;
        max-width: 444px;
        margin: 0;
        text-align: left;
        background: url(../../../assets/images/global/iconWhite.png) no-repeat 0 0;
        font-weight: bold;
        margin-bottom: 48px;
    }
    .webp .whiteHeader {
        color: #fff;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 4px 0 15px 50px;
        max-width: 444px;
        margin: 0;
        text-align: left;
        background: url(../../../assets/images/global/iconWhite.webp) no-repeat 0 0;
        font-weight: bold;
        margin-bottom: 48px;
    }
    .descriptionWhite {
        color: #fff;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 0 20% 0 50px;
        /* padding-left: 50px; */
        margin: 0;
        margin-bottom: 50px;
        margin-top: 4px;
        line-height: 25px;
    }
    .descriptionDark {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 0;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
        float: left;
        padding-right: 50px;
    }
    .no-webp .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 4px 0 15px 55px;
        max-width: 450px;
        margin: 0;
        margin-right: 5px;
        background: url(../../../assets/images/global/iconGray.png) no-repeat center left;
        font-weight: bold;
        margin-bottom: 48px;
        float: left;
    }
    .webp .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 4px 0 15px 55px;
        max-width: 450px;
        margin: 0;
        margin-right: 5px;
        background: url(../../../assets/images/global/iconGray.webp) no-repeat center left;
        font-weight: bold;
        margin-bottom: 48px;
        float: left;
    }
    .descriptionWhiteSecondary {
        color: #FFFFFF;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        padding: 0 80px 0 80px;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
    }
    .whiteHeaderSecondary {
        color: #FFFFFF;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        font-weight: bold;
    }
    .hidden-md-and-up {
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -13px;
    }
    .hidden-md-and-up img{
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -17px;
    }
    @media only screen and (max-width: 768px) {
        .cuttingLineImage {
            background: none;
            min-height: 0;
        }
        #cuttingLineBrochure {
            margin-top: 0;
        }
        .whiteHeader {
            margin-bottom: 0;
            margin-top: 0;
        }
        .whiteLine {
            margin-top: 0;
        }
    }
</style>
