<template>
	<div id="contactUs" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
        <v-container grid-list-xl id="mainContactHolder">
			<v-layout row wrap>
                <white>
                    <v-layout row wrap class="mainHolder">
                        <v-container grid-list-xl id="contactHolder">
                            <v-layout row wrap>
                                <v-flex md2 xs12></v-flex>
                                <v-flex md5 xs12>
                                    <div class="darkLine"></div>
                                    <h4 class="darkHeader">Contacts</h4>
                                </v-flex>
                                <v-flex md5 xs12 >
                                    <!-- <img style="cursor: pointer;" @click="openBrochureModal()" src="../../assets/images/global/downloadProfistopBrochure.png" id="downloadProfistopBrochure"/> -->
                                    <picture style="cursor: pointer;" @click="openBrochureModal()">
                                        <source srcset="../../assets/images/global/downloadProfistopBrochure.webp" type="image/webp">
                                        <source srcset="../../assets/images/global/downloadProfistopBrochure.png" type="image/png"> 
                                        <img src="../../assets/images/global/downloadProfistopBrochure.png" alt="PS Brochure">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex md2 xs12></v-flex>
                                <v-flex md5 xs12>
                                    <v-layout row wrap>
                                        <v-flex md12 xs12>
                                            <h4 class="darkHeaderSecondary">AUSTRALIA</h4>
                                            <h4 class="descriptionDarkSecondary">PROFIFEED TECHNOLOGIES</h4>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap>
                                        <v-flex md5 xs12>
                                            <ul class="contact-info">
                                                <li class="phone">1300 776 700</li>
                                                <li class="email"><a href="mailto:info@profistop.com.au" style="color:#2d2d2d" onmouseover="this.href=this.href.replace(/x/g,'');">info@profistop.com.au</a></li>
                                                <li class="address">47 Healey Road, Dandenong <br>South, VIC 3175, Australia</li>
                                            </ul>
                                        </v-flex>
                                        <v-flex md5 xs12>
                                            <div>
                                                <a class="contactModalTrigger" @click="openContactModal()">Contact Form</a>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex md5 xs12 >
                                    <v-layout row wrap>
                                        <v-flex md12 xs12>
                                            <h4 class="darkHeaderSecondary">EUROPE</h4>
                                            <h4 class="descriptionDarkSecondary">PROFIFEED TECHNOLOGIES</h4>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap>
                                        <v-flex md5 xs12>
                                            <ul class="contact-info">
                                                <li class="phone">+381 37 202722</li>
                                                <li class="email"><a href="mailto:info@profistop.com.au" style="color:#2d2d2d" onmouseover="this.href=this.href.replace(/x/g,'');">info@profistop.com.au</a></li>
                                                <li class="address">Majke Jugovica 25A, <br> Kruševac, 37000, Serbia</li>
                                            </ul>
                                        </v-flex>
                                        <v-flex md5 xs12>
                                            <div>
                                                <a class="contactModalTrigger" @click="openContactModal()">Contact Form</a>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-layout row wrap style="min-height: 360px; margin-bottom: 50px;">
                                    <v-flex md2 xs12></v-flex>
                                    <v-flex md5 xs12>
                                        <!-- <img class="contactImages" src="../../assets/images/contact/au.jpg"/> -->
                                        <picture class="contactImages">
                                            <source srcset="../../assets/images/contact/au.webp" type="image/webp">
                                            <source srcset="../../assets/images/contact/au.jpg" type="image/jpeg"> 
                                            <img src="../../assets/images/contact/au.jpg" alt="Contact AU">
                                        </picture>
                                        <iframe class="iframeMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3142.831061459284!2d145.22159731597804!3d-38.02771775409058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad61424bf897f99%3A0x4404f39a225982c0!2sReconEng+Industrial+Engineering!5e0!3m2!1sen!2s!4v1478702052997" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                    </v-flex>
                                    <v-flex md5 xs12>
                                        <!-- <img class="contactImages" src="../../assets/images/contact/rs.jpg"/> -->
                                        <picture class="contactImages">
                                            <source srcset="../../assets/images/contact/rs.webp" type="image/webp">
                                            <source srcset="../../assets/images/contact/rs.jpg" type="image/jpeg"> 
                                            <img src="../../assets/images/contact/rs.jpg" alt="Contact RS">
                                        </picture>
                                        <iframe class="iframeMap" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1445.1159455714724!2d21.3250806!3d43.580886!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xce7321bdeddfa4a8!2sRECONENG+MDS!5e0!3m2!1sen!2s!4v1480329808670" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                    </v-flex>
                                </v-layout>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </white>
			</v-layout>
		</v-container>
        <contact-modal></contact-modal>
        <brochure-modal></brochure-modal>
        <p id="brochureType" style="display: none;">Profistop</p>
	</div>
</template>
<script>
    import White from '../helper_components/White.vue';
    import ContactModal from '../helper_components/ContactModal.vue';
    import BrochureModal from '../helper_components/BrochureModal.vue';
	export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'ProfiStop',
            // all titles will be injected into this template
            titleTemplate: '%s | Contact Us',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: 'Leading measuring stop and automatic saw solutions for your cutting production. Fast payback and ROI, game-changing efficiency.'
                }
            ],
        },
		components: {
            'white': White,
            'contact-modal': ContactModal,
            'brochure-modal': BrochureModal
        },
        methods: {
            swipeLeft() {
                this.$router.push('/home')
            },
            swipeRight() {
                this.$router.push('/who-we-are')
            },
            openContactModal() {
                document.getElementById('contactTrigger').click();
            },
            openBrochureModal() {
                document.getElementById('brochureTrigger').click();
            }
        }
    }
</script>
<style scoped>
    #imageHolder {
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        bottom: 25px;
        right: -8%;
    }
    .iframeMap {
        max-width: 480px;
        width: 100%;
        height: 348px;
        border-bottom: 1px solid #3778ba;
        border-left: 1px solid #3778ba;
        border-right: 1px solid #3778ba;
        float: left;
    }
    .contactImages {
        max-width: 480px;
        width: 100%;
        margin-top: 32px;
        float: left;
        border-top: 1px solid #3778ba;
        border-left: 1px solid #3778ba;
        border-right: 1px solid #3778ba;
    }
    contact-info {
        padding-left: 0;
    }
    .no-webp .phone {
        background: url(../../assets/images/global/phoneIco.png) no-repeat 0% 3px;
        padding-left: 24px;
    }
    .no-webp .email {
        background: url(../../assets/images/global/emailIco.png) no-repeat 0% 3px;
        padding-left: 24px;
    }
    .no-webp .address {
        background: url(../../assets/images/global/pinIco.png) no-repeat 0% 3px;
        padding-left: 24px;
    }
    .webp .phone {
        background: url(../../assets/images/global/phoneIco.webp) no-repeat 0% 3px;
        padding-left: 24px;
    }
    .webp .email {
        background: url(../../assets/images/global/emailIco.webp) no-repeat 0% 3px;
        padding-left: 24px;
    }
    .webp .address {
        background: url(../../assets/images/global/pinIco.webp) no-repeat 0% 3px;
        padding-left: 24px;
    }
    .contactModalTrigger {
        /* display: inline-block; */
        text-align: center;
        border: none;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        background-color: #0066ca;
        color: #fff;
        cursor: pointer;
        font-weight: normal;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        text-decoration: none;
        width: 186px;
        height: 45px;
        line-height: 45px;
        float: left;
    }
    li {
        list-style: none;
        text-align: left;
        padding-left: 0px;
        display: block;
    }
    .darkLine {
        width: 120px;
        height: 3px;
        background-color: #222;
        margin-bottom: 16px;
    }
    #contactHolder {
        padding-bottom: 0;
        margin-bottom: -13px;
    }
    #mainContactHolder {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    #downloadProfistopBrochure {
        float: left;
    }
    .descriptionDark {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 0;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
        float: left;
        padding-right: 50px;
    }
    .no-webp .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 17px 0 15px 55px;
        max-width: 370px;
        margin: 0;
        margin-right: 5px;
        background: url(../../assets/images/contact/contactIco.png) no-repeat center left;
        font-weight: bold;
        margin-bottom: 48px;
        float: left;
    }
    .webp .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 17px 0 15px 55px;
        max-width: 370px;
        margin: 0;
        margin-right: 5px;
        background: url(../../assets/images/contact/contactIco.webp) no-repeat center left;
        font-weight: bold;
        margin-bottom: 48px;
        float: left;
    }
    .descriptionDarkSecondary {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        font-weight: bold;
        padding: 0;
        margin: 0;
    }
    .darkHeaderSecondary {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        font-weight: bold;
        margin: 0;
    }
    .hidden-md-and-up {
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -13px;
    }
    .hidden-md-and-up img{
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -17px;
    }
    @media only screen and (max-width: 768px) {
        .mainHolder {
            background: none;
            min-height: 0;
        }
        #downloadProfistopBrochure {
            margin-top: 0;
        }
        .whiteHeader {
            margin-bottom: 0;
            margin-top: 0;
        }
        .whiteLine {
            margin-top: 0;
        }
    }
</style>
