<template>
	<v-app style="overflow-y: hidden; overflow-x: hidden;">
		<div v-if="mainLoading" class="mainLoading">
			<half-circle-spinner style="transform: translate(-50%, -50%); position: absolute; left: 50%; top: 50%;"
				:animation-duration="1000"
				:size="80"
				:color="'#0066cc'"
			/>
		</div>
		<div id="popupClosed" style="display: none;">false</div>
		<div v-if="!mainLoading" v-cloak>
			<pre-header></pre-header>
			<main-header></main-header>
			<!-- header place -->
			<!-- components view -->
			<transition name="slide-fade">
				<router-view></router-view>
			</transition>
			<!-- <products></products> -->
			<main-footer></main-footer>
			<second-footer></second-footer>
			<!-- footer place -->
		</div>
	</v-app>
</template>
<script>
	import PreHeader from './components/header_footer/PreHeader.vue';
	import Header from './components/header_footer/Header.vue';
	import Footer from './components/header_footer/Footer.vue';
	import SecondFooter from './components/header_footer/SecondFooter.vue';
	import Blue from './components/helper_components/Blue.vue';
	import White from './components/helper_components/White.vue';
	import Products from './components/main_pages/Products.vue';
	import Lazer from './components/main_pages/products/Lazer.vue';
	import Basic from './components/main_pages/products/Basic.vue'
	import Advanced from './components/main_pages/products/Advanced.vue';
	import AutoSaw from './components/main_pages/products/AutoSaw.vue';
	import CuttingLine from './components/main_pages/products/CuttingLine.vue';
	import Tables from './components/helper_components/Tables.vue';
	import WhyProfistop from './components/main_pages/WhyProfistop.vue';
	import WhoWeAre from './components/main_pages/WhoWeAre.vue';
	import OptiCut from './components/main_pages/OptiCut.vue';
	import ContactUs from './components/main_pages/ContactUs.vue';
	import { HalfCircleSpinner } from 'epic-spinners';
	export default {
		data() {
			return {
				mainLoading: true
			}
		},
		mounted() {
			var that = this;
			setTimeout(() => {
				that.mainLoading = false
			}, 2000);
		},
		components: {
			'half-circle-spinner': HalfCircleSpinner,
			'pre-header': PreHeader,
			'main-header' : Header,
			'main-footer': Footer,
			'second-footer': SecondFooter,
			'blue': Blue,
			'white': White,
			'products': Products,
			'lazer': Lazer,
			'basic': Basic,
			'advanced': Advanced,
			'automatic-saw': AutoSaw,
			'cutting-line': CuttingLine,
			'tables': Tables,
			'why-profistop': WhyProfistop,
			'who-we-are': WhoWeAre,
			'opticut': OptiCut,
			'contact': ContactUs
		}
	}
</script>
<style>
	v-cloak { display: none }
	html {
		width: 100%;
	}
	body {
		font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
		font-size: 14px;
		line-height: 1.42857143;
		color: #333;
		background-color: #fff;
	}
	.titleLine {
        width: 120px;
        height: 3px;
        background-color: #2f2f2f;
        margin-bottom: 16px;
        margin: 0 0 16px 15%;
    }
	.findOutMore {
		width: 200px;
		height: 40px;
		border: 1px solid black;
		background-color: rgb(255, 215, 0);
		color: black;
		margin-top: 50px;
		font-size: 15px;
	}
	.findOutMore:hover {
		background-color: rgb(55, 175, 0);
		color: white;
	}
	.slide-fade-enter-active {
		transition: all .3s ease;
	}
	.slide-fade-leave-active {
		transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}
	.slide-fade-enter, .slide-fade-leave-to
		/* .slide-fade-leave-active below version 2.1.8 */ {
		transform: translateX(10px);
		opacity: 0;
	}
	.router-link-active {
		border-bottom: 5px solid #007ce6!important;
	}
	img {
		max-width: 100%;
	}
	.mainLoading {
		width: 100vw;
		height: 100vh;
		background: #222;
		position: absolute;
	}
</style>