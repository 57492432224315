<template>
    <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="500px">
            <v-btn color="primary" dark slot="activator" class="contactModalTrigger" id="contactTrigger">Contact Us</v-btn>
            <v-card class="backImage">
                <v-btn icon @click.native="dialog = false" light>
                    <v-icon>close</v-icon>
                </v-btn>
                <v-card-title>
                    <span class="headline">Contact Us</span>
                </v-card-title>
                <v-card-text>
                    Thank you for your interest in ProfiStop. <br>
                    Please use this form if you have any questions about our 
                    products and we'll get back with you very soon.
                </v-card-text>
                
                <v-card-text>
                    <v-container grid-list-md>
                        <v-form  v-model="valid" ref="contactForm">
                            <v-layout wrap>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field label="Your Name" hint="Enter your full name" v-model="name" :rules="nameRules" required></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field label="Your Email address" hint="Enter your email" v-model="email" :rules="emailRules" required></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field label="Your Phone Number" hint="Enter your phone number" v-model="phone" :rules="phoneRules" required></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field label="Your Country" hint="Enter your country" v-model="country" :rules="countryRules" required></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field label="Your State" hint="Enter your state" v-model="state" :rules="stateRules" required></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field multi-line label="Type Your Message Here" v-model="message" :rules="messageRules" required></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-form>
                    </v-container>
                    <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" dark @click.native="dialog = false">Cancel</v-btn>
                    <vue-recaptcha @verify="afterRecaptchaVerify" sitekey="6LcX4UoUAAAAAI8o465brHjLeIPhq3c19qD5auXu" size="invisible" ref="invisibleRecaptcha">
                        <v-btn
                            color="primary" dark
                            @click.native="dialog = false;"
                            :disabled="!valid">
                            SUBMIT
                        </v-btn>
                    </vue-recaptcha>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-layout row justify-center>
            <v-dialog v-model="dialogInfo" persistent max-width="290">
            <v-btn color="primary" dark slot="activator" id="contactDownloadInfoActivator" style="display: none;">Contact</v-btn>
            <v-card>
                <v-card-title class="headline" id="infoTitle"></v-card-title>
                <v-card-text id="infoText"></v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat @click.native="dialogInfo = false">OK</v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-layout>
    </v-layout>
</template>
<script>
    import VueRecaptcha from 'vue-recaptcha';
    import axios from 'axios';
    export default {
        components: { VueRecaptcha },
        data: () => ({
            dialog: false,
            dialogInfo: false,
            valid: false,
            name: '',
            nameRules: [
                v => !!v || 'Name is required'
            ],
            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],
            phone: '',
            phoneRules: [
                v=> !!v || 'Phone is required',
                v=> /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) || 'Phone number must be valid'
            ],
            country: '',
            countryRules: [
                v=> !!v || 'Country is required'
            ],
            state: '',
            stateRules: [
                v=> !!v || 'State is required'
            ],
            message: '',
            messageRules: [
                v=> !!v || 'Message is required'
            ],
            erros: []
        }),
        methods: {
            sendContactDetails(recaptchaResponse) {
                if (this.$refs.contactForm.validate()) {
                    var userFormData = {};
                    userFormData = {
                        formType: 'contact',
                        name: this.name,
                        email: this.email,
                        phone: this.phone,
                        // company: this.company,
                        state: this.state,
                        country: this.country,
                        message: this.message,
                        grec: recaptchaResponse,
                        brochureType: "none"
                    };
                    var contactFormLocal = this.$refs.contactForm;
                    var axiosData = JSON.stringify(userFormData);
                    axios.post(`https://profistop.com/newEmailScript.php`, {
                        axiosData
                    })
                    .then(function (response) {
                        contactFormLocal.reset();
                        if(userFormData.brochureType == 'none' && response.data.result) {
                            document.getElementById('infoTitle').innerHTML = "Message Sent.";
                            document.getElementById('infoText').innerHTML = response.data.returnMessage;
                            document.getElementById('contactDownloadInfoActivator').click();
                        } else {
                            document.getElementById('infoTitle').innerHTML = "Error Sending Message.";
                            document.getElementById('infoText').innerHTML = response.data.returnMessage;
                            document.getElementById('contactDownloadInfoActivator').click();
                        }
                    })
                    .catch(e => {
                        this.errors.push(e);
                    });
                }
            },
            afterRecaptchaVerify(recaptchaResponse) {
                this.sendContactDetails(recaptchaResponse);
            }
        }
    }
</script>
<style scoped>
    .application .theme--dark.btn.btn--disabled:not(.btn--icon):not(.btn--flat) {
        background-color: hsla(0, 0%, 22%, 0.59)!important;
        color: white!important;
    }
    .contactModalTrigger {
        display: none;
    }
    .card__text {
        padding: 0 16px 0 16px;
    }
    .no-webp .backImage {
        background: url(../../assets/images/backgrounds/white.jpg) top left no-repeat #fff;
        border-radius: 0px;
        border: none;
    }
    .webp .backImage {
        background: url(../../assets/images/backgrounds/white.webp) top left no-repeat #fff;
        border-radius: 0px;
        border: none;
    }
</style>