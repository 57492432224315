<template>
	<div id="productivity" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
        <v-container grid-list-xl id="productivityContainer">
            <v-layout row wrap>
				<white style="padding-top: 50px;">
                    <v-layout row wrap>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <v-flex lg6 md6>
                                    <div class="titleLine"></div>
                                    <h1 id="productivityTitle">
                                        ProfiStop&reg;<br>
                                        High-Productivity Cutting
                                    </h1>
                                </v-flex>
                                <v-flex md5 xs12 style="float: right;">
                                    <!-- <img style="cursor: pointer;" @click="openBrochureModal()" src="../../assets/images/global/downloadProfistopBrochure.png" /> -->
                                    <picture style="cursor: pointer;" @click="openBrochureModal()">
                                        <source srcset="../../assets/images/global/downloadProfistopBrochure.webp" type="image/webp">
                                        <source srcset="../../assets/images/global/downloadProfistopBrochure.png" type="image/png"> 
                                        <img src="../../assets/images/global/downloadProfistopBrochure.png" alt="PS Brochure">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg4 md3 sm12 xs12 align-center>
                                </v-flex>
                                <v-flex lg4 md6 sm12 xs12>
                                    <!-- <img src="../../assets/images/global/lightBulb.png" style="height: 50px;"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/global/lightBulb.webp" type="image/webp">
                                        <source srcset="../../assets/images/global/lightBulb.png" type="image/png"> 
                                        <img src="../../assets/images/global/lightBulb.png" alt="Light">
                                    </picture>
                                    <h4 class="darkHeader" style="font-size: 20px;">Did you know!</h4>
                                    <p class="descriptionDark">
                                        You can have your cut lists optimised on all ProfiStop machines!
                                        In addition to waste savings, cut list optimisation results in a significant productivity improvement as you handle less material and make fewer trim cuts!
                                    </p>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg6 md6 sm12 xs12>
                                    <!-- <img src="../../assets/images/productivity/fifoIllustration.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/productivity/fifoIllustration.webp" type="image/webp">
                                        <source srcset="../../assets/images/productivity/fifoIllustration.png" type="image/png"> 
                                        <img src="../../assets/images/productivity/fifoIllustration.png" alt="Fifo">
                                    </picture>
                                    <h4 class="darkHeader">
                                        Typical FIFO jobbing with manual stop
                                    </h4>
                                    <p class="descriptionDark">
                                        <ul>
                                            <li class="liOne">
                                                Material required: 6 m, qty = 30
                                            </li>
                                            <li class="liTwo">
                                                Material waste: 21.7%
                                            </li>
                                            <li class="liThree">
                                                Cutting time: 68 mins
                                            </li>
                                        </ul>
                                    </p>
                                </v-flex>
                                <v-flex lg6 md6 sm12 xs12>
                                    <!-- <img src="../../assets/images/productivity/PSillustration.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/productivity/PSillustration.webp" type="image/webp">
                                        <source srcset="../../assets/images/productivity/PSillustration.png" type="image/png"> 
                                        <img src="../../assets/images/productivity/PSillustration.png" alt="PSillustration">
                                    </picture>
                                    <h4 class="darkHeader">
                                        ProfiStop&reg; Measuring Stop with optimisation
                                    </h4>
                                    <p class="descriptionDark">
                                        <ul>
                                            <li class="liOne">
                                                Material required: 6 m, qty = 24
                                            </li>
                                            <li class="liTwo">
                                                Material waste: 2.1%
                                            </li>
                                            <li class="liThree">
                                                Cutting time: 56 mins
                                            </li>
                                        </ul>
                                    </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg12 md12 sm12 xs12 align-center>
                                    <button class="findOutMore" @click="openContactModal()">Find Out More</button>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </white>
			</v-layout>
            <v-layout row wrap>
                <blue style="padding-top: 50px;">
                    <v-layout row wrap>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <v-flex lg2 md0 sm12 xs12 align-center>
                                </v-flex>
                                <v-flex lg4 md6 sm12 xs12>
                                    <div style="height: 62px;">
                                        <!-- <img src="../../assets/images/productivity/productivity1.png"/> -->
                                        <picture>
                                            <source srcset="../../assets/images/productivity/productivity1.webp" type="image/webp">
                                            <source srcset="../../assets/images/productivity/productivity1.png" type="image/png"> 
                                            <img src="../../assets/images/productivity/productivity1.png" alt="Productivity1">
                                        </picture>
                                    </div>
                                    <div class="backPanel">
                                        <h4 class="whiteHeader">
                                            On-Screen Optimisation
                                        </h4>
                                        <p class="descriptionWhite">
                                            Get the benefit of yield-maximised cut lists without spending your time managing stock. Send the same cut list you use today to ProfiStop&reg;. Your operator selects material input, ProfiStop&reg; optimises the cut list. Material waste is reduced, without thinking about it.
                                        </p>
                                    </div>
                                </v-flex>
                                <v-flex lg4 md6 sm12 xs12>
                                    <!-- <img src="../../assets/images/productivity/productivity2.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/productivity/productivity2.webp" type="image/webp">
                                        <source srcset="../../assets/images/productivity/productivity2.png" type="image/png"> 
                                        <img src="../../assets/images/productivity/productivity2.png" alt="Productivity2">
                                    </picture>
                                    <div class="backPanel">
                                        <h4 class="whiteHeader">
                                            Adhesive Labels and Bar-Code Printing
                                        </h4>
                                        <p class="descriptionWhite">
                                            Sort out your many parts by labelling them. ProfiStop&reg; can print job or part information labels automatically for every cut made including bar codes. Especially effective for yield-optimised job lists or large production volume cutting.
                                        </p>
                                    </div>
                                </v-flex>
                                <v-flex lg2 md2 sm12 xs12 align-center>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg4 md3 sm12 xs12>
                                </v-flex>
                                <v-flex lg4 md6 sm12 xs12>
                                    <!-- <img src="../../assets/images/productivity/productivity3.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/productivity/productivity3.webp" type="image/webp">
                                        <source srcset="../../assets/images/productivity/productivity3.png" type="image/png"> 
                                        <img src="../../assets/images/productivity/productivity3.png" alt="Productivity3">
                                    </picture>
                                    <div class="backPanel">
                                        <h4 class="whiteHeader">
                                            OptiCut-1D&trade;
                                        </h4>
                                        <p class="descriptionWhite">
                                            Our cloud-based designs, job and material management solution seamlessly integrated with ProfiFeed Cutting Line. Automatically group and optimise your many daily jobs and send to the machine with a click of the mouse.
                                        </p>
                                    </div>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg12 md12 sm12 xs12 align-center>
                                    <button class="findOutMore" @click="openContactModal()">Find Out More</button>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </blue>
			</v-layout>
		</v-container>
        <contact-modal></contact-modal>
        <brochure-modal></brochure-modal>
        <p id="brochureType" style="display: none;">Profistop</p>
	</div>
</template>
<script>
    import Blue from '../helper_components/Blue.vue';
    import White from '../helper_components/White.vue';
    import ContactModal from '../helper_components/ContactModal.vue';
    import BrochureModal from '../helper_components/BrochureModal.vue';
	export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'ProfiStop',
            // all titles will be injected into this template
            titleTemplate: '%s | Productivity Focussed',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: 'Leading measuring stop and automatic saw solutions for your cutting production. Fast payback and ROI, game-changing efficiency.'
                }
            ],
        },
		components: {
			'blue': Blue,
			'white': White,
            'contact-modal': ContactModal,
            'brochure-modal': BrochureModal
        },
        methods: {
            swipeLeft() {
                this.$router.push('/opticut')
            },
            swipeRight() {
                this.$router.push('/products')
            },
            openContactModal() {
                document.getElementById('contactTrigger').click();
            },
            openBrochureModal() {
                document.getElementById('brochureTrigger').click();
            }
        }
    }
</script>
<style scoped>
    .backPanel {
        min-height: 250px;
        margin-top: 22px;
        padding-bottom: 30px;
        padding-top: 30px;
        background-color: rgba(255,255,255,0.1);
        overflow: hidden;
    }
    ul {
        list-style: none;
        list-style: none;
        text-align: left;
        margin: 0px;
        padding: 0 0 0 5%;;
    }
    li {
        padding-left: 30px;
    }
    .no-webp .liOne {
        background: url(../../assets/images/productivity/wood-ico.png) no-repeat 0% 3px;
    }
    .no-webp .liTwo {
        background: url(../../assets/images/productivity/waste-ico.png) no-repeat 0% 3px;
    }
    .no-webp .liThree {
        background: url(../../assets/images/productivity/time-ico.png) no-repeat 0% 3px;
    }
    .grid-list-xl {
        margin-right: auto!important;
    }
    #productivity {
        padding: 0;
    }
    .no-webp #productivityTitle {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 0 0 15px 50px;
        max-width: 444px;
        margin: 0 0 0 15%;
        text-align: left;
        background: url(../../assets/images/productivity/productivityIco.png) no-repeat -7px 0;
        font-weight: bold;
    }
    .webp .liOne {
        background: url(../../assets/images/productivity/wood-ico.webp) no-repeat 0% 3px;
    }
    .webp .liTwo {
        background: url(../../assets/images/productivity/waste-ico.webp) no-repeat 0% 3px;
    }
    .webp .liThree {
        background: url(../../assets/images/productivity/time-ico.webp) no-repeat 0% 3px;
    }
    .grid-list-xl {
        margin-right: auto!important;
    }
    #productivity {
        padding: 0;
    }
    .webp #productivityTitle {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 0 0 15px 50px;
        max-width: 444px;
        margin: 0 0 0 15%;
        text-align: left;
        background: url(../../assets/images/productivity/productivityIco.webp) no-repeat -7px 0;
        font-weight: bold;
    }
    #productivityContainer {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    .whiteHeader {
        color: #fff;
        font-size: 16px;
        line-height: 1.5rem;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        font-weight: bold;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 0;
    }
    .descriptionWhite {
        color: #fff;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        padding-left: 15%;
        padding-right: 15%;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
        margin-top: 0;
    }
    .descriptionDark {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        padding-left: 15%;
        padding-right: 15%;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
        margin-top: 0;
    }
    .darkHeader {
        color: #2f2f2f;
        font-size: 16px;
        line-height: 1.5rem;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        font-weight: bold;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 0;
    }
    .hidden-md-and-up {
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -17px;
    }
</style>