import Home from './components/main_pages/Home.vue';
import Products from './components/main_pages/Products.vue';
import ProductivityFocus from './components/main_pages/ProductivityFocus.vue';
import OptiCut from './components/main_pages/OptiCut.vue';
import WhyProfistop from './components/main_pages/WhyProfistop.vue';
import News from './components/main_pages/News.vue';
import NewsArticle from './components/helper_components/NewsArticle.vue';
import ContactUs from './components/main_pages/ContactUs.vue';
import WhoWeAre from './components/main_pages/WhoWeAre.vue';
import Lazer from './components/main_pages/products/Lazer.vue';
import Basic from './components/main_pages/products/Basic.vue';
import Advanced from './components/main_pages/products/Advanced.vue';
import AutoSaw from './components/main_pages/products/AutoSaw.vue';
import CuttingLine from './components/main_pages/products/CuttingLine.vue';

export const routes = [
    { path: '/', component: Home},
    { path: '/home/', component: Home },
    { path: '/products/', component: Products },
    { path: '/products/lazer/', component: Lazer },
    { path: '/products/basic/', component: Basic },
    { path: '/products/advanced/', component: Advanced },
    { path: '/products/automatic-saws/', component: AutoSaw },
    { path: '/products/cutting-line/', component: CuttingLine },
    { path: '/productivity-focussed/', component: ProductivityFocus },
    { path: '/opticut/', component: OptiCut },
    { path: '/why-profistop/', component: WhyProfistop },
    { path: '/news', component: News },
    { path: '/news/article/:id', component: NewsArticle },
    { path: '/contact/', component: ContactUs },
    { path: '/who-we-are/', component: WhoWeAre },
];