<template>
	<div id="products" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
        <v-container grid-list-xl id="productsContainer">
			<v-layout row wrap>
                <blue>
                    <v-layout row wrap>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <v-flex lg4 offset-lg2 md6 xs12>
                                    <h4 class="whiteHeader">ProfiStop<sup>&reg;</sup> Lazer</h4>
                                    <br>
                                    <p class="descriptionWhite">
                                        A simple, light-duty measuring stop for saws.<br>
                                        Basic go-to-position operation.<br>
                                        Lifetime accuracy guarantee.<br>
                                        Light duty, budget level product.<br>
                                        <router-link to="/products/lazer"  tag="button" class="findOutMore" style="border: 1px solid white; float: left; margin-left: 0;">Find Out More</router-link>
                                    </p>
                                    <br>
                                </v-flex>
                                <v-flex lg4 md6 xs12 class="lazerImage hidden-sm-and-down"></v-flex>
                                <v-flex lg4 md6 xs12 class="hiddem-md-and-up" style="padding: 0; margin-bottom: -18px;">
                                    <!-- <img src="../../assets/images/products/lazerProduct.png" class="hidden-md-and-up"/> -->
                                    <picture class="hidden-md-and-up">
                                        <source srcset="../../assets/images/products/lazerProduct.webp" type="image/webp">
                                        <source srcset="../../assets/images/products/lazerProduct.png" type="image/png"> 
                                        <img src="../../assets/images/products/lazerProduct.png" alt="Lazer">
                                    </picture>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </blue>
			</v-layout>
            <v-layout row wrap>
				<white>
                    <v-layout row wrap>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <!-- <v-flex md2>
                                </v-flex> -->
                                <v-flex lg4 offset-lg2 md6 xs12 class="basicImage hidden-sm-and-down">
                                </v-flex>
                                <v-flex lg4 md6 xs12>
                                    <h4 class="darkHeader">ProfiStop<sup>&reg;</sup> Basic</h4>
                                    <br>
                                    <p class="descriptionDark">
                                        Simple, heavy-duty measuring stop for saws.<br>
                                        Basic go-to-position operation.<br>
                                        Lifetime accuracy guarantee.<br>
                                        Heavy duty, budget level product.<br>
                                        <router-link to="/products/basic"  tag="button" class="findOutMore" style="border: 1px solid black; float: right; margin-left: 0;">Find Out More</router-link>
                                    </p>
                                </v-flex>
                                <v-flex md4 xs12 class="hidden-md-and-up" style="padding: 0;">
                                    <!-- <img src="../../assets/images/products/basic.png" class="hidden-md-and-up"/> -->
                                    <picture class="hidden-md-and-up">
                                        <source srcset="../../assets/images/products/basic.webp" type="image/webp">
                                        <source srcset="../../assets/images/products/basic.png" type="image/png"> 
                                        <img src="../../assets/images/products/basic.png" alt="PS Basic">
                                    </picture>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </white>
			</v-layout>
            <v-layout row wrap>
                <blue>
                    <v-layout row wrap>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <!-- <v-flex md2>
                                </v-flex> -->
                                <v-flex lg4 offset-lg2 md6 xs12>
                                    <h4 class="whiteHeader">ProfiStop<sup>&reg;</sup> Advanced</h4>
                                    <br>
                                    <p class="descriptionWhite">
                                        Advanced, heavy-duty measuring stop for saws.<br>
                                        Advanced productivity and efficiency functions.<br>
                                        Lifetime accuracy guarantee.<br>
                                        Heavy-duty, advanced-level product.<br>
                                        <router-link to="/products/advanced"  tag="button" class="findOutMore" style="border: 1px solid white; float: left; margin-left: 0;">Find Out More</router-link>
                                    </p>
                                    <br>
                                </v-flex>
                                <v-flex lg6 md6 xs12 class="advancedImage hidden-sm-and-down"></v-flex>
                                <v-flex lg4 md6 xs12 class="hiddem-md-and-up" style="padding: 0; margin-bottom: -18px;">
                                    <!-- <img src="../../assets/images/products/advanced.png" class="hidden-md-and-up"/> -->
                                    <picture class="hidden-md-and-up">
                                        <source srcset="../../assets/images/products/advanced.webp" type="image/webp">
                                        <source srcset="../../assets/images/products/advanced.png" type="image/png"> 
                                        <img src="../../assets/images/products/advanced.png" alt="PS Advanced">
                                    </picture>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </blue>
			</v-layout>
            <v-layout row wrap>
				<white>
                    <v-layout row wrap>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <v-flex lg6 md6 xs12 class="autosawImage hidden-sm-and-down">
                                </v-flex>
                                <v-flex lg4 md6 xs12 sm12>
                                    <h4 class="darkHeader">ProfiStop<sup>&reg;</sup> Automatic Saws</h4>
                                    <br>
                                    <p class="descriptionDark">
                                        Any semi-automatic saw fully automated.<br>
                                        Massive productivity improvement and labour reduction.<br>
                                        Proprietary jobbing for simple cutting of many short-run parts.<br>
                                        For aluminium, steel and timber processing.<br>
                                        <router-link to="/products/automatic-saws"  tag="button" class="findOutMore" style="border: 1px solid black; float: right; margin-left: 0;">Find Out More</router-link>
                                    </p>
                                </v-flex>
                                <v-flex md4 xs12 class="hidden-md-and-up" style="padding: 0;">
                                    <!-- <img src="../../assets/images/products/autosaw.png" class="hidden-md-and-up"/> -->
                                    <picture class="hidden-md-and-up">
                                        <source srcset="../../assets/images/products/autosaw.webp" type="image/webp">
                                        <source srcset="../../assets/images/products/autosaw.png" type="image/png"> 
                                        <img src="../../assets/images/products/autosaw.png" alt="PS Auto Saw">
                                    </picture>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </white>
			</v-layout>
            <v-layout row wrap>
                <blue>
                    <v-layout row wrap>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <!-- <v-flex lg2>
                                </v-flex> -->
                                <v-flex lg4 offset-lg2 md6 xs12>
                                    <h4 class="whiteHeader">ProfiStop<sup>&reg;</sup> Cutting Lines</h4>
                                    <br>
                                    <p class="descriptionWhite">
                                        Comprehensive automatic cutting solution.<br>
                                        Continuous loading, cutting-to-length, completed parts collection.<br>
                                        Proprietary jobbing for simple management of many short-run parts.<br>
                                        Solutions for aluminium, steel and timber processing.<br>
                                        <router-link to="/products/cutting-line"  tag="button" class="findOutMore" style="border: 1px solid white; float: left; margin-left: 0;">Find Out More</router-link>
                                    </p>
                                    <br>
                                </v-flex>
                                <v-flex lg6 md6 xs12 class="cuttinglineImage hidden-sm-and-down"></v-flex>
                                <v-flex sm12 xs12 class="hidden-md-and-up" style="padding: 0;">
                                    <!-- <img src="../../assets/images/products/cuttingline.png" class="hidden-md-and-up"/> -->
                                    <picture class="hidden-md-and-up">
                                        <source srcset="../../assets/images/products/cuttingline.webp" type="image/webp">
                                        <source srcset="../../assets/images/products/cuttingline.png" type="image/png"> 
                                        <img src="../../assets/images/products/cuttingline.png" alt="PS Cutting line">
                                    </picture>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </blue>
			</v-layout>
		</v-container>
	</div>
</template>
<script>
    import Blue from '../helper_components/Blue.vue';
    import White from '../helper_components/White.vue';
	export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'ProfiStop',
            // all titles will be injected into this template
            titleTemplate: '%s | Products',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: 'Do you cut aluminium, wood or steel? Our measuring stops and automatic saws are game-changing saw automation, increasing productivity and eliminating labour. Learn more now!'
                }
            ],
        },
		components: {
			'blue': Blue,
			'white': White
        },
        methods: {
            swipeLeft() {
                this.$router.push('/productivity-focussed')
            },
            swipeRight() {
                this.$router.push('/home')
            }
        }
    }
</script>
<style scoped>
    .grid-list-xl {
        margin-right: 0!important;
    }
    #products {
        padding: 0;
    }
    #productsContainer {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    .no-webp .whiteHeader {
        color: #fff;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 4px 0 15px 50px;
        max-width: 444px;
        margin: 0;
        text-align: left;
        background: url(../../assets/images/global/iconWhite.png) no-repeat 0 0;
        font-weight: bold;
        /* margin-bottom: 48px; */
    }
    .webp .whiteHeader {
        color: #fff;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 4px 0 15px 50px;
        max-width: 444px;
        margin: 0;
        text-align: left;
        background: url(../../assets/images/global/iconWhite.webp) no-repeat 0 0;
        font-weight: bold;
        /* margin-bottom: 48px; */
    }
    .descriptionWhite {
        color: #fff;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 0;
        padding-left: 50px;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
    }
    .descriptionDark {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: right;
        padding: 0;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
        float: right;
        padding-right: 50px;
    }
    .no-webp .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: right;
        padding: 4px 55px 15px 0;
        max-width: 357px;
        margin: 0;
        margin-right: 5px;
        background: url(../../assets/images/global/iconGray.png) no-repeat center right;
        font-weight: bold;
        /* margin-bottom: 48px; */
        float: right;
    }
    .webp .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: right;
        padding: 4px 55px 15px 0;
        max-width: 357px;
        margin: 0;
        margin-right: 5px;
        background: url(../../assets/images/global/iconGray.webp) no-repeat center right;
        font-weight: bold;
        /* margin-bottom: 48px; */
        float: right;
    }
    .no-webp .lazerImage {
        background: url(../../assets/images/products/lazerProduct.png) no-repeat right bottom;
        height: 100%;
        min-height: 445px;
        margin-bottom: -24px;
        background-size: contain;
    }
    .no-webp .basicImage {
        background: url(../../assets/images/products/basic.png) no-repeat left bottom;
        height: 100%;
        min-height: 445px;
        margin-bottom: -24px;
        background-size: contain;
    }
    .no-webp .advancedImage {
        background: url(../../assets/images/products/advanced.png) no-repeat right bottom;
        height: 100%;
        min-height: 445px;
        margin-bottom: -24px;
        background-size: contain;
    }
    .no-webp .autosawImage {
        background: url(../../assets/images/products/autosaw.png) no-repeat right bottom;
        height: 100%;
        min-height: 445px;
        margin-bottom: -24px;
        background-size: contain;
    }
    .no-webp .cuttinglineImage {
        background: url(../../assets/images/products/cuttingline.png) no-repeat right bottom;
        height: 100%;
        min-height: 445px;
        margin-bottom: -12px;
        background-size: contain;
    }
    .hidden-md-and-up {
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -17px;
    }
    .webp .lazerImage {
        background: url(../../assets/images/products/lazerProduct.webp) no-repeat right bottom;
        height: 100%;
        min-height: 445px;
        margin-bottom: -24px;
        background-size: contain;
    }
    .webp .basicImage {
        background: url(../../assets/images/products/basic.webp) no-repeat left bottom;
        height: 100%;
        min-height: 445px;
        margin-bottom: -24px;
        background-size: contain;
    }
    .webp .advancedImage {
        background: url(../../assets/images/products/advanced.webp) no-repeat right bottom;
        height: 100%;
        min-height: 445px;
        margin-bottom: -24px;
        background-size: contain;
    }
    .webp .autosawImage {
        background: url(../../assets/images/products/autosaw.webp) no-repeat right bottom;
        height: 100%;
        min-height: 445px;
        margin-bottom: -24px;
        background-size: contain;
    }
    .webp .cuttinglineImage {
        background: url(../../assets/images/products/cuttingline.webp) no-repeat right bottom;
        height: 100%;
        min-height: 445px;
        margin-bottom: -12px;
        background-size: contain;
    }
    @media only screen and (max-width: 768px) {
        .lazerImage {
            background: none;
            min-height: 0;
        }
        .basicImage {
            background: none;
            min-height: 0;
        }
        .advancedImage {
            background: none;
            min-height: 0;
        }
        .autosawImage {
            background: none;
            min-height: 0;
        }
        .cuttinglineImage {
            background: none;
            min-height: 0;
        }
    }
</style>
        