<template>
	<div id="tables" style="width: 100%;">
        <v-container grid-list-xl style="padding-bottom: 0; margin-bottom: -29px;">
            <v-layout row wrap>
               <!--  <v-flex md3>
                </v-flex> -->
                <v-flex xl12 lg12 md12 sm12 xs12>
                    <h4 class="darkHeaderTables">ProfiStop<sup>&reg;</sup> Tables</h4>
                    <br>
                    <p class="descriptionDarkTables">
                        All tables are available as general purpose infeed / outfeed conveyor tables.
                        <br>
                        Tables available in any length in 1.5m and 3m modular sections.
                        <br>
                        All tables are of heavy-duty 3 mm thick steel construction.
                        <br>
                        All tables available in roller widths of 250 and 400 mm.
                        <br>
                        All rollers available in steel or PVC finish.
                    </p>
                </v-flex>
            </v-layout>
            <v-layout row wrap hidden-sm-and-down>
                <v-flex md12 xs12 class="tablesImage">
                    <!-- <img src="../../assets/images/tables/tablesFull.png" style="width: 100%;"/> -->
                    <picture style="width: 100%;">
                        <source srcset="../../assets/images/tables/tablesFull.webp" type="image/webp">
                        <source srcset="../../assets/images/tables/tablesFull.png" type="image/png"> 
                        <img style="width: 100%;" src="../../assets/images/tables/tablesFull.png" alt="Tables">
                    </picture>
                </v-flex>
            </v-layout>
            <v-layout row wrap hidden-md-and-up>
                <v-flex md12 xs12 sm12 class="tablesImageAlt">
                    <p>Roller-Only Table</p>
                    <!-- <img src="../../assets/images/tables/tables1.png"/> -->
                    <picture>
                        <source srcset="../../assets/images/tables/tables1.webp" type="image/webp">
                        <source srcset="../../assets/images/tables/tables1.png" type="image/png"> 
                        <img src="../../assets/images/tables/tables1.png" alt="Tables1">
                    </picture>
                </v-flex>
                <v-flex md12 xs12 sm12 class="tablesImageAlt">
                    <p>Roller-Flat Table</p>
                    <!-- <img src="../../assets/images/tables/tables2.png"/> -->
                    <picture>
                        <source srcset="../../assets/images/tables/tables2.webp" type="image/webp">
                        <source srcset="../../assets/images/tables/tables2.png" type="image/png"> 
                        <img src="../../assets/images/tables/tables2.png" alt="Tables2">
                    </picture>
                </v-flex>
                <v-flex md12 xs12 sm12 class="tablesImageAlt">
                    <p>Roller-Flat with Storage Table</p>
                    <!-- <img src="../../assets/images/tables/tables3.png"/> -->
                    <picture>
                        <source srcset="../../assets/images/tables/tables3.webp" type="image/webp">
                        <source srcset="../../assets/images/tables/tables3.png" type="image/png"> 
                        <img src="../../assets/images/tables/tables3.png" alt="Tables3">
                    </picture>
                </v-flex>
                <v-flex md12 xs12 sm12 class="tablesImageAlt" style="margin-bottom: 15px;">
                    <p>Flat-Only Table</p>
                    <!-- <img src="../../assets/images/tables/tables4.png"/> -->
                    <picture>
                        <source srcset="../../assets/images/tables/tables4.webp" type="image/webp">
                        <source srcset="../../assets/images/tables/tables4.png" type="image/png"> 
                        <img src="../../assets/images/tables/tables4.png" alt="Tables4">
                    </picture>
                </v-flex>
            </v-layout>
		</v-container>
	</div>
</template>
<script>
    import Blue from './Blue.vue';
    import White from './White.vue';
	export default {
		components: {
			'blue': Blue,
			'white': White
        },
    }
</script>
<style scoped>
    .tablesImageAlt {
        height: max-content;
        margin-bottom: 50px;
    }
    .descriptionDarkTables {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        padding: 0;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
    }
    .no-webp .darkHeaderTables {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        padding: 20px 0 15px 47px;
        max-width: 357px;
        margin: auto;
        background: url(../../assets/images/global/iconGray.png) no-repeat center left;
        font-weight: bold;
        margin-bottom: 13px;
    }
    .webp .darkHeaderTables {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        padding: 20px 0 15px 47px;
        max-width: 357px;
        margin: auto;
        background: url(../../assets/images/global/iconGray.webp) no-repeat center left;
        font-weight: bold;
        margin-bottom: 13px;
    }
    .hidden-md-and-up {
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -13px;
    }
    .hidden-md-and-up img{
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -17px;
    }
    @media only screen and (max-width: 768px) {
        
    }
</style>
        