<template>
	<div id="autoSaw" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
        <v-container grid-list-xl id="mainAutoSawHolder">
			<v-layout row wrap>
                <div class="hidden-sm-and-down" style="z-index: 1000; margin-left: 1%; position: fixed; left: 0; top: 50%; transform: translate(0%, -50%); font-size: 35px;">
                    <router-link :to="{ path: '/products/advanced' }" style="color: #222;">
                        <font-awesome-icon :icon="leftArrowIcon" />
                    </router-link>
                </div>
                <div class="hidden-sm-and-down" style="z-index: 1000; margin-right: 1%; position: fixed; right: 0; top: 50%; transform: translate(0%, -50%); font-size: 35px;">
                    <router-link :to="{ path: '/products/cutting-line' }" style="color: #222;">
                        <font-awesome-icon :icon="rightArrowIcon" />
                    </router-link>
                </div>
                <white>
                    <v-layout row wrap class="autoSawImage">
                        <v-container grid-list-xl id="autoSawHolder">
                            <v-layout row wrap>
                                <!-- <v-flex md2>
                                </v-flex> -->
                                <v-flex md6 xs12>
                                    <div class="darkLine"></div>
                                    <h4 class="darkHeader">ProfiStop<sup>&reg;</sup> <br>Automatic Saws</h4>
                                </v-flex>
                                <v-flex md6 xs12 >
                                    <!-- <img style="cursor: pointer;" @click="openBrochureModal()" src="../../../assets/images/global/downloadProfistopBrochure.png" id="downloadProfistopBrochure"/> -->
                                    <picture style="cursor: pointer;" @click="openBrochureModal()">
                                        <source srcset="../../../assets/images/global/downloadProfistopBrochure.webp" type="image/webp">
                                        <source srcset="../../../assets/images/global/downloadProfistopBrochure.png" type="image/png"> 
                                        <img src="../../../assets/images/global/downloadProfistopBrochure.png" alt="PS Brochure">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <!-- <v-flex md2>
                                </v-flex> -->
                                <v-flex md5 xs12>
                                    <p class="descriptionDark">
                                        ProfiStop&reg; automatic saws are a simple solution for fully automating aluminium, 
                                        steel or timber cutting. ProfiStop system can automate any semi-automatic saw to 
                                        produce a fully automated cutting-to-length process. 
                                        <br><br>
                                        Exceptional process productivity improvements, typically of several hundred
                                        percent, with also dramatically reduced or eliminated heavy manual labour content
                                        associated with cutting.
                                    </p>
                                    <ul class="text-left" style="padding-left: 20px; padding-bottom: 100px;">
                                        <li v-for="item in autosawitems" :key="item.text">
                                            <div :style="{ 'background-image': 'url(' + item.image + ')', paddingLeft: '90px' }">
                                                <p style="margin-bottom: 27px; padding-bottom: 13px; padding-top: 13px; display: inline-block; font-size: 16px;">
                                                    {{ item.text }}
                                                </p>
                                                <br>
                                            </div>
                                            <!-- <img :src = item.image style="bottom: 0;"/>  -->
                                        </li>
                                    </ul>
                                </v-flex>
                                <v-flex md7 xs12 class="hidden-sm-and-down" id="imageHolder">
                                </v-flex>
                                <v-flex md4 xs12 class="hidden-md-and-up">
                                    <picture>
                                        <source srcset="../../../assets/images/products/auto-saw/faSawTop.webp" type="image/webp">
                                        <source srcset="../../../assets/images/products/auto-saw/faSawTop.png" type="image/png"> 
                                        <img src="../../../assets/images/products/auto-saw/faSawTop.png" alt="PS Automatic Saw">
                                    </picture>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </white>
			</v-layout>
            <v-layout row wrap>
                <blue>
                    <v-layout row wrap>
                        <v-flex md1 xs12></v-flex>
                        <v-flex md5 xs12 sm12 style="margin-top: 30px!important;">
                            <iframe src="https://www.youtube.com/embed/Ot5Mdw0f_rw?rel=0" frameborder="0" allowfullscreen style="width: 100%; height: 360px;"></iframe>
                        </v-flex>
                        <v-flex md5 xs12 sm12>
                            <h4 style="color: #fff; margin-top: 30px!important;">
                                <b>Intro to ProfiStop<sup>&reg;</sup></b><br>
                                Simple Length Stop<br>
                                Solution for your Saws
                            </h4>
                            <button class="findOutMore" @click="openContactModal()">Find Out More</button>
                        </v-flex>
                    </v-layout>
                </blue>
            </v-layout>
            <v-layout row wrap>
                <white>
                    <v-layout row wrap class="faImageBottom" style="padding-top: 50px;">
                        <v-container grid-list-xl id="faBottom">
                            <v-layout row wrap>
                                <v-flex md3 xs12></v-flex>
                                <v-flex md3 xs12>
                                    <!-- <img src="../../../assets/images/products/auto-saw/fasaw9.png" /> -->
                                    <picture>
                                        <source srcset="../../../assets/images/products/auto-saw/fasaw9.webp" type="image/webp">
                                        <source srcset="../../../assets/images/products/auto-saw/fasaw9.png" type="image/png"> 
                                        <img src="../../../assets/images/products/auto-saw/fasaw9.png" alt="PS Automatic Saw">
                                    </picture>
                                    <br>
                                    <h4 class="darkHeaderSecondary">
                                        On-Screen Automatic Yield Optimisation
                                    </h4>
                                    <p class="descriptionDarkSecondary">
                                        Get the benefit of reduced material wastage but without wasting time managing stock. 
                                        Send the same cut list you use today to ProfiStop&reg;. 
                                        On-the-fly optimisation automatically measures the length of stock, 
                                        optimises your job list for maximum material yield, and ProfiStop&reg; Auto Saw makes the cuts.
                                    </p>
                                </v-flex>
                                <!-- <v-flex md1 xs12 ></v-flex> -->
                                <v-flex md3 xs12 >
                                    <!-- <img src="../../../assets/images/products/auto-saw/fasaw10.png" /> -->
                                    <picture>
                                        <source srcset="../../../assets/images/products/auto-saw/fasaw10.webp" type="image/webp">
                                        <source srcset="../../../assets/images/products/auto-saw/fasaw10.png" type="image/png"> 
                                        <img src="../../../assets/images/products/auto-saw/fasaw10.png" alt="PS Automatic Saw">
                                    </picture>
                                    <br>
                                    <h4 class="darkHeaderSecondary">
                                        Automatic Defect Removal
                                    </h4>
                                    <p class="descriptionDarkSecondary">
                                        Don’t let any scratches through with ProfiStop&reg; on-the-fly defect removal and optimisation. 
                                        Your operator marks the defects using a pen, ProfiStop&reg; system scans the marked 
                                        locations and automatically cuts out the damaged material while cutting your 
                                        parts with maximum material utilisation from the remaining defect-free material.
                                    </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex md3 xs12></v-flex>
                                <v-flex md3 xs12>
                                    <!-- <img src="../../../assets/images/products/auto-saw/fasaw11.png" /> -->
                                    <picture>
                                        <source srcset="../../../assets/images/products/auto-saw/fasaw11.webp" type="image/webp">
                                        <source srcset="../../../assets/images/products/auto-saw/fasaw11.png" type="image/png"> 
                                        <img src="../../../assets/images/products/auto-saw/fasaw11.png" alt="PS Automatic Saw">
                                    </picture>
                                    <br>
                                    <h4 class="darkHeaderSecondary">
                                        Automatic Part Marking
                                    </h4>
                                    <p class="descriptionDarkSecondary">
                                        Sort out your many parts daily by labelling them. 
                                        Our automatic saws can print job or part information on your 
                                        parts by burning or pin marking automatically for every cut made.
                                    </p>
                                </v-flex>
                                <v-flex md3 xs12>
                                    <!-- <img src="../../../assets/images/products/auto-saw/fasaw12.png" /> -->
                                    <picture>
                                        <source srcset="../../../assets/images/products/auto-saw/fasaw12.webp" type="image/webp">
                                        <source srcset="../../../assets/images/products/auto-saw/fasaw12.png" type="image/png"> 
                                        <img src="../../../assets/images/products/auto-saw/fasaw12.png" alt="PS Automatic Saw">
                                    </picture>
                                    <br>
                                    <h4 class="darkHeaderSecondary">
                                        OptiCut-1D&trade;
                                    </h4>
                                    <p class="descriptionDarkSecondary">
                                        Our cloud-based design, job and stock management solution seamlessly integrated 
                                        with your ProfiStop&reg; Auto Saw. Automatically group and optimise your cutting jobs, 
                                        and send the cut list to your auto saw is as easy as drag-and-drop. 
                                        Use OptiCut-1D&trade; for managing all your design BOMs, jobs, stock and material procurement.
                                    </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex md12 xs12>
                                    <picture>
                                        <source srcset="../../../assets/images/products/auto-saw/faSawBottom.webp" type="image/webp">
                                        <source srcset="../../../assets/images/products/auto-saw/faSawBottom.png" type="image/png"> 
                                        <img src="../../../assets/images/products/auto-saw/faSawBottom.png" alt="PS Automatic Saw">
                                    </picture>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </white>
            </v-layout>
		</v-container>
        <contact-modal></contact-modal>
        <brochure-modal></brochure-modal>
        <p id="brochureType" style="display: none;">Profistop</p>
        <demo-modal style="position: fixed!important; right: 1%!important; bottom: 1%!important; max-width: 200px!important;"></demo-modal>
        <v-btn id="fabButton" fab dark fixed
                bottom
                right 
                @click="openDemoModal()">+
        </v-btn>
	</div>
</template>
<script>
    import Blue from '../../helper_components/Blue.vue';
    import White from '../../helper_components/White.vue';
    import Tables from '../../helper_components/Tables.vue';
    import ProductsDemoModal from '../../helper_components/ProductsDemoModal.vue';
    import ContactModal from '../../helper_components/ContactModal.vue';
    import BrochureModal from '../../helper_components/BrochureModal.vue';
    import FontAwesomeIcon from '@fortawesome/vue-fontawesome';
    import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/fontawesome-free-solid';
	export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'ProfiStop',
            // all titles will be injected into this template
            titleTemplate: '%s | Automatic Saw',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: 'Do you cut aluminium, wood or steel? Improve your cutting productivity by up to 800% while simultaneously reducing labour. Are you ready to automate your business? Click here now.'
                }
            ],
        },
		components: {
			'blue': Blue,
            'white': White,
            'tables': Tables,
            'contact-modal': ContactModal,
            'brochure-modal': BrochureModal,
            'font-awesome-icon': FontAwesomeIcon,
            'demo-modal': ProductsDemoModal
        },
        data() { 
            return {
                autosawitems: [
                    { text: 'Any semi-automatic saw fully automated.', image: require('../../../assets/images/products/auto-saw/fasaw1.png') },
                    { text: '100 to 600 kg (220 to 1320 lbs) material pushing capacity.', image: require('../../../assets/images/products/auto-saw/fasaw2.png') },
                    { text: '±0.1 mm material positioning repeatability.', image: require('../../../assets/images/products/auto-saw/fasaw3.png') },
                    { text: 'Multi-times cutting process productivity improvement.', image: require('../../../assets/images/products/auto-saw/fasaw4.png') },
                    { text: 'Heavy reduction in the cutting labour content and intensity.', image: require('../../../assets/images/products/auto-saw/fasaw5.png') },
                    { text: 'Proprietary jobbing system for simple management of many short-run parts.', image: require('../../../assets/images/products/auto-saw/fasaw6.png') },
                    { text: 'OptiCut1D cutting production management system integrated.', image: require('../../../assets/images/products/auto-saw/fasaw7.png') },
                    { text: 'Automatic saw solutions for aluminium, steel, timber processing and more.', image: require('../../../assets/images/products/auto-saw/fasaw8.png') }
                ]
            }
        },
        methods: {
            swipeLeft() {
                this.$router.push('/products/cutting-line')
            },
            swipeRight() {
                this.$router.push('/products/advanced')
            },
            openContactModal() {
                document.getElementById('contactTrigger').click();
            },
            openBrochureModal() {
                document.getElementById('brochureTrigger').click();
            },
            openDemoModal() {
                document.getElementById('demoModalTrigger').click();
            }
        },
        computed: {
            leftArrowIcon () {
                return faArrowAltCircleLeft
            },
            rightArrowIcon () {
                return faArrowAltCircleRight
            }
        }
    }
</script>
<style scoped>
    #fabButton {
        border-radius: 50px;
        width: 50px!important;
        height: 50px;
        font-size: 20px;
        max-width: 50px!important;
        position: fixed!important;
        right: 55px!important;
        bottom: 1%!important;
        background-color: orange;
    }
    #faBottom {
        /* background: url(../../../assets/images/products/auto-saw/faSawBottom.png); */
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        /* bottom: 25px;
        right: -8%; */
    }
    .no-webp #imageHolder {
        background: url(../../../assets/images/products/auto-saw/faSawTop.png);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        bottom: 25px;
        right: -8%;
    }
    .webp #imageHolder {
        background: url(../../../assets/images/products/auto-saw/faSawTop.webp);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        bottom: 25px;
        right: -8%;
    }
    li {
        list-style: none;
        text-align: left;
        padding-left: 0px;
        display: block;
    }
    .darkLine {
        width: 120px;
        height: 3px;
        background-color: #222;
        margin-bottom: 16px;
    }
    #autoSawHolder {
        padding-bottom: 0;
        margin-bottom: -13px;
    }
    #mainAutoSawHolder {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    .no-webp .whiteHeader {
        color: #fff;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 4px 0 15px 50px;
        max-width: 444px;
        margin: 0;
        text-align: left;
        background: url(../../../assets/images/global/iconWhite.png) no-repeat 0 0;
        font-weight: bold;
        margin-bottom: 48px;
    }
    .webp .whiteHeader {
        color: #fff;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 4px 0 15px 50px;
        max-width: 444px;
        margin: 0;
        text-align: left;
        background: url(../../../assets/images/global/iconWhite.webp) no-repeat 0 0;
        font-weight: bold;
        margin-bottom: 48px;
    }
    .descriptionWhite {
        color: #fff;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 0 20% 0 50px;
        /* padding-left: 50px; */
        margin: 0;
        margin-bottom: 50px;
        margin-top: 4px;
        line-height: 25px;
    }
    .descriptionDark {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 0;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
        padding-right: 50px;
    }
    .no-webp .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 4px 0 15px 55px;
        max-width: 370px;
        margin: 0;
        margin-right: 5px;
        background: url(../../../assets/images/global/iconGray.png) no-repeat center left;
        font-weight: bold;
        margin-bottom: 48px;
        float: left;
    }
    .webp .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 4px 0 15px 55px;
        max-width: 370px;
        margin: 0;
        margin-right: 5px;
        background: url(../../../assets/images/global/iconGray.webp) no-repeat center left;
        font-weight: bold;
        margin-bottom: 48px;
        float: left;
    }
    .descriptionDarkSecondary {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        padding: 0;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
    }
    .darkHeaderSecondary {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        font-weight: bold;
    }
    .advancedImage {
        height: 100%;
        width: 100%;
        max-width: 100%;
        min-height: 445px;
        margin-bottom: -24px;
        max-height: 100%;
    }
    .hidden-md-and-up {
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -13px;
    }
    .hidden-md-and-up img{
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -17px;
    }
    @media only screen and (max-width: 768px) {
        .whiteHeader {
            margin-bottom: 0;
            margin-top: 0;
        }
        .whiteLine {
            margin-top: 0;
        }
    }
</style>
