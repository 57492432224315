<template>
	<div id="lazer" v-touch:swipe.left="swipeLeft">
        <v-container grid-list-xl id="mainLazerHolder">
			<v-layout row wrap>
                <div class="hidden-sm-and-down" style="z-index: 1000; margin-left: 1%; position: fixed; right: 0; top: 50%; transform: translate(0%, -50%); font-size: 35px;">
                    <router-link :to="{ path: '/products/basic' }" style="color: #222;">
                        <font-awesome-icon :icon="rightArrowIcon" />
                    </router-link>
                </div>
                <blue>
                    <v-layout row wrap class="lazerImage">
                        <v-container grid-list-xl id="lazerHolder">
                            <v-layout row wrap>
                                <!-- <v-flex md2>
                                </v-flex> -->
                                <v-flex md6 xs12>
                                    <div class="whiteLine"></div>
                                    <h4 class="whiteHeader">ProfiStop<sup>&reg;</sup> Lazer</h4>
                                </v-flex>
                                <v-flex md4 xs12 >
                                    <!-- <img style="cursor: pointer;" @click="openBrochureModal()" src="../../../assets/images/global/lazerBrochure.png" id="lazerBrochure"/> -->
                                    <picture style="cursor: pointer;" @click="openBrochureModal()">
                                        <source srcset="../../../assets/images/global/lazerBrochure.webp" type="image/webp">
                                        <source srcset="../../../assets/images/global/lazerBrochure.png" type="image/png"> 
                                        <img src="../../../assets/images/global/lazerBrochure.png" alt="PS Lazer Brochure">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <!-- <v-flex md2>
                                </v-flex> -->
                                <v-flex md5 xs12>
                                    <p class="descriptionWhite">
                                        ProfiStop Lazer is a light-duty, simple to use measuring stop solution for your saws. 
                                        It is designed to be a no frills, simple product but with all the productivity benefits 
                                        of an automated measuring stop.
                                    </p>
                                    <ul class="text-left" style="padding-left: 20px; padding-bottom: 100px;">
                                        <li v-for="item in items" :key="item.text">
                                            <div :style="{ 'background-image': 'url(' + item.image + ')', paddingLeft: '90px' }">
                                                <p style="padding-bottom: 27px; padding-top: 27px; display: inline-block; margin-bottom: 0; font-size: 16px;">
                                                    {{ item.text }}
                                                </p>
                                                <br>
                                            </div>
                                        </li>
                                    </ul>
                                </v-flex>
                                <v-flex md7 xs12 class="hidden-md-and-down" id="imageHolder">
                                    <!-- <img src="../../../assets/images/products/lazer/lazerImage.png" style="bottom: -120px; right: 0; position: absolute"/> -->
                                </v-flex>
                                <v-flex md4 xs12 class="hidden-md-and-up">
                                    <!-- <img src="../../../assets/images/products/lazer/lazerImage.png"/> -->
                                    <picture>
                                        <source srcset="../../../assets/images/products/lazer/lazerImage.webp" type="image/webp">
                                        <source srcset="../../../assets/images/products/lazer/lazerImage.png" type="image/png"> 
                                        <img src="../../../assets/images/products/lazer/lazerImage.png" alt="PS Lazer">
                                    </picture>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </blue>
			</v-layout>
            <v-layout row wrap>
                <white>
                    <tables></tables>
                </white>
            </v-layout>
		</v-container>
        <brochure-modal></brochure-modal>
        <p id="brochureType" style="display: none;">Lazer</p>
        <demo-modal style="position: fixed!important; right: 1%!important; bottom: 1%!important; max-width: 200px!important;"></demo-modal>
        <v-btn id="fabButton" fab dark fixed
                bottom
                right 
                @click="openDemoModal()">+
        </v-btn>
	</div>
</template>
<script>
    import Blue from '../../helper_components/Blue.vue';
    import White from '../../helper_components/White.vue';
    import Tables from '../../helper_components/Tables.vue';
    import ProductsDemoModal from '../../helper_components/ProductsDemoModal.vue';
    import BrochureModal from '../../helper_components/BrochureModal.vue';
    import FontAwesomeIcon from '@fortawesome/vue-fontawesome';
    import { faArrowAltCircleRight } from '@fortawesome/fontawesome-free-solid';
	export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'ProfiStop',
            // all titles will be injected into this template
            titleTemplate: '%s | Lazer',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: 'Does your business cut aluminium, wood or steel? ProfiStop Lazer is a simple, inexpensive and high-accuracy measuring stop for your production. Are you ready to automate your business? Click here now.'
                }
            ],
        },
		components: {
			'blue': Blue,
            'white': White,
            'tables': Tables,
            'brochure-modal': BrochureModal,
            'font-awesome-icon': FontAwesomeIcon,
            'demo-modal': ProductsDemoModal
        },
        data() {
            return {
                items: [
                    { text: '20 kg (44 lbs) piece pushing capacity.', image: require('../../../assets/images/products/lazer/lazer1.png') },
                    { text: '±0.25 mm positioning repeatability.', image: require('../../../assets/images/products/lazer/lazer2.png') },
                    { text: 'Simple to use colour touch-screen interface.', image: require('../../../assets/images/products/lazer/lazer3.png') },
                    { text: 'Basic go-to-position operation.', image: require('../../../assets/images/products/lazer/lazer4.png') },
                    { text: 'Automatic part label printing and bar-code jobs input.', image: require('../../../assets/images/products/lazer/lazer5.png') },
                    { text: 'OptiCut1D cutting production system integrated.', image: require('../../../assets/images/products/lazer/lazer6.png') }
                ]
            }
        },
        methods: {
            swipeLeft() {
                this.$router.push('/products/basic')
            },
            openBrochureModal() {
                document.getElementById('brochureTrigger').click();
            },
            openDemoModal() {
                document.getElementById('demoModalTrigger').click();
            }
        },
        computed: {
            rightArrowIcon () {
                return faArrowAltCircleRight
            }
        }
    }
</script>
<style scoped>
    #fabButton {
        border-radius: 50px;
        width: 50px!important;
        height: 50px;
        font-size: 20px;
        max-width: 50px!important;
        position: fixed!important;
        right: 55px!important;
        bottom: 1%!important;
        background-color: orange;
    }
    .no-webp #imageHolder {
        background: url(../../../assets/images/products/lazer/lazerImage.png);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        bottom: 25px;
    }
    .webp #imageHolder {
        background: url(../../../assets/images/products/lazer/lazerImage.webp);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        bottom: 25px;
    }
    li {
        list-style: none;
        text-align: left;
        padding-left: 0px;
        display: block;
    }
    .whiteLine {
        width: 120px;
        height: 3px;
        background-color: #FFFFFF;
        margin-bottom: 16px;
        margin-top: 92px;
    }
    #lazerHolder {
        padding-bottom: 0;
        margin-bottom: -13px;
    }
    #mainLazerHolder {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    #lazerBrochure {
        margin-top: 92px;
    }
    .no-webp .whiteHeader {
        color: #fff;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 4px 0 15px 50px;
        max-width: 444px;
        margin: 0;
        text-align: left;
        background: url(../../../assets/images/global/iconWhite.png) no-repeat 0 0;
        font-weight: bold;
        margin-bottom: 48px;
    }
    .webp .whiteHeader {
        color: #fff;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 4px 0 15px 50px;
        max-width: 444px;
        margin: 0;
        text-align: left;
        background: url(../../../assets/images/global/iconWhite.webp) no-repeat 0 0;
        font-weight: bold;
        margin-bottom: 48px;
    }
    .descriptionWhite {
        color: #fff;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: left;
        padding: 0 20% 0 50px;
        /* padding-left: 50px; */
        margin: 0;
        margin-bottom: 50px;
        margin-top: 4px;
        line-height: 25px;
    }
    .descriptionDark {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: right;
        padding: 0;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
        padding-right: 50px;
    }
    .no-webp .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: right;
        padding: 4px 55px 15px 0;
        max-width: 357px;
        margin: 0;
        margin-right: 5px;
        background: url(../../../assets/images/global/iconGray.png) no-repeat center right;
        font-weight: bold;
        margin-bottom: 48px;
        float: right;
    }
    .webp .darkHeader {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: right;
        padding: 4px 55px 15px 0;
        max-width: 357px;
        margin: 0;
        margin-right: 5px;
        background: url(../../../assets/images/global/iconGray.png) no-repeat center right;
        font-weight: bold;
        margin-bottom: 48px;
        float: right;
    }
    .lazerImage {
        height: 100%;
        width: 100%;
        max-width: 100%;
        min-height: 445px;
        margin-bottom: -24px;
        max-height: 100%;
    }
    .hidden-md-and-up {
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -13px;
    }
    .hidden-md-and-up img{
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -17px;
    }
    @media only screen and (max-width: 768px) {
        .lazerImage {
            background: none;
            min-height: 0;
        }
        #lazerBrochure {
            margin-top: 0;
        }
        .whiteHeader {
            margin-bottom: 0;
            margin-top: 0;
        }
        .whiteLine {
            margin-top: 0;
        }
    }
</style>
        