<template>
	<div id="opticut" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
        <v-container grid-list-xl id="opticutContainer">
            <v-layout row wrap>
				<white style="padding-top: 50px;">
                    <v-layout row wrap>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <v-flex lg6 md6>
                                    <div class="titleLine"></div>
                                    <h1 id="opticutTitle">
                                        OptiCut-1D&trade; Cloud-Based Cutting<br>
                                        Production Management Solution
                                    </h1>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg12 md12 sm12 xs12>
                                    <!-- <img src="../../assets/images/opticut/opticutBanner.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/opticut/opticutBanner.webp" type="image/webp">
                                        <source srcset="../../assets/images/opticut/opticutBanner.png" type="image/png"> 
                                        <img src="../../assets/images/opticut/opticutBanner.png" alt="Opticut Banner">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap style="border-top: 1px solid rgba(220,220,220,0.5);">
                                <v-flex lg4 offset-lg2 md6 sm12 xs12>
                                    <p class="descriptionDark">
                                        OptiCut1D - Cloud-Based ERP management system solution that allows you to manage and optimise your BOMs, cut lists and material procurement seamlessly. Keep all your design BOMs in one place and easily input or change them for production. Group and optimise your cut lists for yield maximisation as well as seamlessly integrate with all ProfiStop&reg; length stops and automatic saws.
                                    </p>
                                </v-flex>
                                <v-flex lg4 md6 sm12 xs12>
                                    <!-- <img src="../../assets/images/opticut/screen1.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/opticut/screen1.webp" type="image/webp">
                                        <source srcset="../../assets/images/opticut/screen1.png" type="image/png"> 
                                        <img src="../../assets/images/opticut/screen1.png" alt="Opticut 1">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap style="border-top: 1px solid rgba(220,220,220,0.5);">
                                <v-flex lg4 offset-lg2 md6 sm12 xs12 hidden-sm-and-down>
                                    <!-- <img src="../../assets/images/opticut/screen2.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/opticut/screen2.webp" type="image/webp">
                                        <source srcset="../../assets/images/opticut/screen2.png" type="image/png"> 
                                        <img src="../../assets/images/opticut/screen2.png" alt="Opticut 2">
                                    </picture>
                                </v-flex>
                                <v-flex lg4 md6 sm12 xs12>
                                    <p class="descriptionDark">
                                        Input your BOMs seamlessly via XLSX files or manually. OptiCut-1D&trade; automatically groups and optimises them into cuting jobs, prepares job lists and input material schedules, printed or wireless. You will know where your production is at all times. Sudden change in jobs? No problem! OptiCut-1D&trade; will instantly add, remove and re-optimise jobs on your operator's cut list on the ProfiStop&reg;
                                    </p>
                                </v-flex>
                                <v-flex lg4 offset-lg2 md6 sm12 xs12 hidden-md-and-up>
                                    <!-- <img src="../../assets/images/opticut/screen2.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/opticut/screen2.webp" type="image/webp">
                                        <source srcset="../../assets/images/opticut/screen2.png" type="image/png"> 
                                        <img src="../../assets/images/opticut/screen2.png" alt="Opticut 2">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap style="border-top: 1px solid rgba(220,220,220,0.5);">
                                <v-flex lg4 offset-lg2 md6 sm12 xs12>
                                    <p class="descriptionDark">
                                        Cloud-based software allows you to manage and know the status of your production from any computer with internet access. Don't bother with licences or networking. We maintain, update and upgrade OptiCut-1D&trade; behinde the scenes; you just run your production efficiently.
                                    </p>
                                </v-flex>
                                <v-flex lg4 md6 sm12 xs12>
                                    <!-- <img src="../../assets/images/opticut/screen3.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/opticut/screen3.webp" type="image/webp">
                                        <source srcset="../../assets/images/opticut/screen3.png" type="image/png"> 
                                        <img src="../../assets/images/opticut/screen3.png" alt="Opticut 3">
                                    </picture>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </white>
                <div class="hidden-md-and-down">
                    <a href="https://opticut1d.com" target="_blank" style="text-decoration: none; color: white!important;">
                        <div class="ribbon">
                            <p id="visitText1" style="text-align: center; font-size: 25px; margin-top: 19%; /*transform: rotate(180deg);*/ color: white; margin-bottom: 0;">
                            VISIT
                            </p>
                            <p id="visitText2" style="text-align: center; font-size: 15px; margin-top: -2%; /*transform: rotate(180deg);*/ color: white;">
                            www.opticut1d.com
                            </p>
                        </div>
                    </a>
                </div>
                <div class="hidden-lg-and-up">
                    <a href="https://opticut1d.com" target="_blank" style="text-decoration: none; color: white!important;">
                        <div class="ribbonSmall">
                            <p id="visitText1" style="text-align: center; font-size: 25px; margin-top: 19%; transform: rotate(180deg); color: white; margin-bottom: 0;">
                            VISIT
                            </p>
                            <p id="visitText2" style="text-align: center; font-size: 15px; margin-top: -2%; transform: rotate(180deg); color: white;">
                            www.opticut1d.com
                            </p>
                        </div>
                    </a>
                </div>
			</v-layout>
		</v-container>
	</div>
</template>
<script>
    import Blue from '../helper_components/Blue.vue';
    import White from '../helper_components/White.vue';
	export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'ProfiStop',
            // all titles will be injected into this template
            titleTemplate: '%s | OptiCut-1D',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: 'Make cutting beautiful. Manage and optimise your cutting production from job lists management to material procurement, seamlessly integrated with all ProfiStop machines.'
                }
            ],
        },
		components: {
			'blue': Blue,
			'white': White
        },
        methods: {
            swipeLeft() {
                this.$router.push('/why-profistop')
            },
            swipeRight() {
                this.$router.push('/productivity-focussed')
            }
        }
    }
</script>
<style scoped>
    .no-webp .ribbon {
        background: url(../../assets/images/opticut/visit.png);
        position: fixed!important;
        right: -90px!important;
        top: -23px!important;
        z-index: 999!important;
        overflow: hidden!important;
        width: 272px!important;
        height: 138px!important;
        text-align: right!important;
        transform: rotate(45deg)!important;
        left: auto!important;
    }
    .webp .ribbon {
        background: url(../../assets/images/opticut/visit.webp);
        position: fixed!important;
        right: -90px!important;
        top: -23px!important;
        z-index: 999!important;
        overflow: hidden!important;
        width: 272px!important;
        height: 138px!important;
        text-align: right!important;
        transform: rotate(45deg)!important;
        left: auto!important;
    }
    .no-webp .ribbonSmall {
        background: url(../../assets/images/opticut/visit.png);
        position: fixed;
        left: -88px;
        bottom: -21px;
        z-index: 1;
        overflow: hidden;
        width: 272px;
        height: 138px;
        text-align: right;
        transform: rotate(225deg);
    }
    .webp .ribbonSmall {
        background: url(../../assets/images/opticut/visit.webp);
        position: fixed;
        left: -88px;
        bottom: -21px;
        z-index: 1;
        overflow: hidden;
        width: 272px;
        height: 138px;
        text-align: right;
        transform: rotate(225deg);
    }
    .no-webp body {
        background-image: url(../../assets/images/opticut/background.jpg);
        background-size: cover;
    }
    .webp body {
        background-image: url(../../assets/images/opticut/background.webp);
        background-size: cover;
    }
    .grid-list-xl {
        margin-right: auto!important;
    }
    #opticut {
        padding: 0;
    }
    .no-webp #opticutTitle {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 0 0 15px 50px;
        max-width: 544px;
        margin: 0 0 0 15%;
        text-align: left;
        background: url(../../assets/images/opticut/opticutIco.png) no-repeat 1px 0;
        font-weight: bold;
    }
    .webp #opticutTitle {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 0 0 15px 50px;
        max-width: 544px;
        margin: 0 0 0 15%;
        text-align: left;
        background: url(../../assets/images/opticut/opticutIco.webp) no-repeat 1px 0;
        font-weight: bold;
    }
    #opticutContainer {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    .descriptionDark {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        padding-left: 10%;
        padding-right: 10%;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
        margin-top: 0;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
    }
    .darkHeader {
        color: #2f2f2f;
        font-size: 16px;
        line-height: 1.5rem;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        font-weight: bold;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 0;
    }
    .hidden-md-and-up {
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -17px;
    }
</style>