<template>
  <div class="text-xs-center" style="position: fixed; bottom: 1%; right: 1%;">
    <v-dialog
      :hide-overlay="true"
      :close-on-content-click="false"
      :persistent="true"
      v-model="dialog"
      style="width: 200px; height: 500px; float: right; position: fixed; bottom: 1%; right: 1%;"
    >
    <!-- :nudge-width="200" -->
    <v-btn color="indigo" dark slot="activator" id="demoModalTrigger" style="display: none; position: fixed; bottom: 1%; right: 1%;">Menu as Popover</v-btn>
    <v-card id="demoHolder">
        <v-btn icon @click.native="dialog = false; ppclose()" light>
            <v-icon>close</v-icon>
        </v-btn>
        <v-card-text style="text-align: center;">
            <h6>
                Are you ready to automate your business?
            </h6>
            <h5 style="color: #007cdf;">
                Request a Demo Today.
            </h5>
            <!-- <img src="../../assets/images/products/demoImage.png" style="max-height: 100px;" alt="Demo"> -->
            <picture style="max-height: 100px;">
                <source srcset="../../assets/images/products/demoImage.webp" type="image/webp">
                <source srcset="../../assets/images/products/demoImage.png" type="image/png"> 
                <img style="max-height: 100px;" src="../../assets/images/products/demoImage.png" alt="Demo">
            </picture>
        </v-card-text>
        <v-card-text>
            <v-container grid-list-md>
                <v-form ref="demoForm" v-model="valid">
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12>
                            <v-text-field label="Your Name" hint="Enter your full name" v-model="name" :rules="nameRules" required></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-text-field label="Your Email address" hint="Enter your email" v-model="email" :rules="emailRules" required></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-text-field label="Your Phone Number" hint="Enter your phone number" v-model="phone" :rules="phoneRules" required></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-text-field multi-line label="Type Your Message Here" v-model="message" :rules="messageRules" required></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <vue-recaptcha @verify="afterRecaptchaVerify" sitekey="6LcX4UoUAAAAAI8o465brHjLeIPhq3c19qD5auXu" size="invisible" ref="invisibleRecaptcha">
                <v-btn
                    color="primary" dark
                    @click.native="dialog = false;"
                    :disabled="!valid"
                    style="width: 100%;">
                    Submit My Information
                </v-btn>
            </vue-recaptcha>
          <!-- <v-btn color="primary" dark @click="dialog = false" style="width: 100%;">Submit My Information</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout row justify-center>
        <v-dialog v-model="dialogInfo" persistent max-width="290">
        <v-btn color="primary" dark slot="activator" id="demoInfoActivator" style="display: none;">Demo</v-btn>
        <v-card>
            <v-card-title class="headline" id="infoTitle"></v-card-title>
            <v-card-text id="infoText"></v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" flat @click.native="dialogInfo = false">OK</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-layout>
  </div>
</template>
<script>
    import VueRecaptcha from 'vue-recaptcha';
    import axios from 'axios';
    export default {
        components: { VueRecaptcha },
        data: () => ({
            fav: true,
            dialog: false,
            valid: false,
            name: '',
            dialogInfo: false,
            nameRules: [
                v => !!v || 'Name is required'
            ],
            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],
            phone: '',
            phoneRules: [
                v=> !!v || 'Phone is required',
                v=> /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) || 'Phone number must be valid'
            ],
            message: '',
            messageRules: [
                v=> !!v || 'Message is required'
            ]
        }),
        methods: {
            sendDemoDetails(recaptchaResponse) {
                if (this.$refs.demoForm.validate()) {
                    var userFormData = {};
                    userFormData = {
                        formType: 'demo',
                        name: this.name,
                        email: this.email,
                        phone: this.phone,
                        message: this.message,
                        grec: recaptchaResponse,
                        brochureType: "none"
                    };
                    var demoFormLocal = this.$refs.demoForm;
                    var axiosData = JSON.stringify(userFormData);
                    axios.post(`https://profistop.com/newEmailScript.php`, {
                        axiosData
                    })
                    .then(function (response) {
                        demoFormLocal.reset();
                        if(userFormData.brochureType == 'none' && response.data.result) {
                            document.getElementById('infoTitle').innerHTML = "Message Sent.";
                            document.getElementById('infoText').innerHTML = response.data.returnMessage;
                            document.getElementById('demoInfoActivator').click();
                        } else {
                            document.getElementById('infoTitle').innerHTML = "Error Sending Message.";
                            document.getElementById('infoText').innerHTML = response.data.returnMessage;
                            document.getElementById('demoInfoActivator').click();
                        }
                    })
                    .catch(e => {
                        this.errors.push(e);
                    });
                }
            },
            afterRecaptchaVerify(recaptchaResponse) {
                this.sendDemoDetails(recaptchaResponse);
            },
            ppclose() {
                document.getElementById('popupClosed').innerHTML = 'true';
            }
        },
        created() {
            if(document.getElementById('popupClosed').innerHTML == 'false') {
                setTimeout(() => {
                    document.getElementById('demoModalTrigger').click();
                }, 5000);
            }
        }
    }
</script>
<style scoped>
    .dialog, .dialog__content {
        position: fixed!important;
        bottom: 1%!important;
        right: 1%!important;
        left: auto!important;
        top: auto!important;
        width: auto;
    }
    .no-webp #demoHolder {
        max-width: 290px;
        max-height: 90%;
        overflow: auto;
        position: fixed;
        bottom: 1%;
        right: 55px;
        background: url(../../assets/images/backgrounds/white.jpg) center center;
        border-radius: 0;
        border: 1px solid black;
        z-index: 1001;
    }
    .webp #demoHolder {
        max-width: 290px;
        max-height: 90%;
        overflow: auto;
        position: fixed;
        bottom: 1%;
        right: 55px;
        background: url(../../assets/images/backgrounds/white.webp) center center;
        border-radius: 0;
        border: 1px solid black;
        z-index: 1001;
    }
</style>