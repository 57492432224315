<template>
	<div id="whyProfistop" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
        <v-container grid-list-xl id="whyProfistopContainer">
            <v-layout row wrap>
				<white style="padding-top: 50px;">
                    <v-layout row wrap>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <v-flex lg6 md6>
                                    <div class="titleLine"></div>
                                    <h1 id="whyProfistopTitle">
                                        Why ProfiStop
                                    </h1>
                                </v-flex>
                                <v-flex md5 xs12 style="float: right;">
                                    <!-- <img style="cursor: pointer;" @click="openBrochureModal()" src="../../assets/images/global/downloadProfistopBrochure.png" /> -->
                                    <picture style="cursor: pointer;" @click="openBrochureModal()">
                                        <source srcset="../../assets/images/global/downloadProfistopBrochure.webp" type="image/webp">
                                        <source srcset="../../assets/images/global/downloadProfistopBrochure.png" type="image/png"> 
                                        <img src="../../assets/images/global/downloadProfistopBrochure.png" alt="PS Brochure">
                                    </picture>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg4 md4 sm12 xs12 align-center>
                                    <!-- <img src="../../assets/images/why_profistop/why1.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why1.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why1.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why1.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="darkHeader">Exceptional ROI</h4>
                                    <p class="descriptionDark">
                                        Most users find that their new ProfiStop&reg; pays for itself in under a year.
                                    </p>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why2.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why2.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why2.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why2.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="darkHeader">Lifetime accuracy guarantee</h4>
                                    <p class="descriptionDark">
                                        Our proprietary system ensures accuracy of every ProfiStop machine can be maintained for life. <a @click="openContactModal()" style="cursor: pointer;">Find out more.</a>
                                    </p>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why3.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why3.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why3.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why3.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="darkHeader">Fast and accurate, every time</h4>
                                    <p class="descriptionDark">
                                        ProfiStop&reg; quickly positions itself to your length input, and produces the cuts you need, all the time, every time.
                                    </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why4.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why4.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why4.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why4.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="darkHeader">Don’t waste time entering data</h4>
                                    <p class="descriptionDark">
                                        Send your job lists to ProfiStop straight from your office. They will be on the screen of your operator right away. Just press GO and cut.
                                    </p>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why5.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why5.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why5.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why5.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="darkHeader">Eliminate tiresome mistakes</h4>
                                    <p class="descriptionDark">
                                        When a job is complete, ProfiStop will tell your operator; suggest to go to the next job; as well as tell what stock to fetch.
                                    </p>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why6.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why6.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why6.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why6.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="darkHeader">Focus on the big picture</h4>
                                    <p class="descriptionDark">
                                        Free your operator from the mundane process detail of cutting and their mind will be fresh to make you money in more important ways.
                                    </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why7.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why7.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why7.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why7.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="darkHeader">Get more done, with less</h4>
                                    <p class="descriptionDark">
                                        ProfiStop&reg; super-charges your every worker, and makes a novice produce the same as your best.
                                    </p>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why8.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why8.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why8.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why8.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="darkHeader">Get something from nothing</h4>
                                    <p class="descriptionDark">
                                        ProfiStop&reg; saves you $$ with optimised cut lists but without having to spend time managing stock. Don’t throw away your material, or time.
                                    </p>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why9.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why9.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why9.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why9.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="darkHeader">Australian made and owned</h4>
                                    <p class="descriptionDark">
                                        ProfiStop&reg; is a World-leading Australian technology product. Be a part of our story.
                                    </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg12 md12 sm12 xs12 align-center>
                                    <button class="findOutMore" @click="openContactModal()">Find Out More</button>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </white>
			</v-layout>
            <v-layout row wrap>
                <blue>
                    <v-layout row wrap>
                        <v-container grid-list-xl>
                            <v-layout row wrap>
                                <v-flex lg4 md4 sm12 xs12 align-center>
                                    <!-- <img src="../../assets/images/why_profistop/why10.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why10.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why10.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why10.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="whiteHeader">
                                        Are you so busy that you can not take <br>any more work?
                                    </h4>
                                    <p class="descriptionWhite">
                                        Every month you are likely losing many times more in sales and profit than the cost of investment in a ProfiStop&reg;.
                                    </p>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why11.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why11.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why11.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why11.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="whiteHeader">
                                        Are you stressed trying to organise <br>many things for your production?
                                    </h4>
                                    <p class="descriptionWhite">
                                        We can only keep 7±2 things in our head at any one time. Let ProfiStop&reg; take care of all your cutting data and management and you can focus on important things.
                                    </p>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why12.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why12.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why12.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why12.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="whiteHeader">
                                        Do you have two, three or five saws in <br>your production?
                                    </h4>
                                    <p class="descriptionWhite">
                                        You can dramatically decrease space and labour allocated to cutting, while simultaneously improving your production output.
                                    </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why13.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why13.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why13.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why13.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="whiteHeader">
                                        Do you discover onsite you have <br>incorrectly manufactured parts?
                                    </h4>
                                    <p class="descriptionWhite">
                                        Incorrectly manufactured parts are one of the most common and costly on-site work stoppages. Organise your production with a ProfiStop&reg; and no hole will be out of place when you need it the most.
                                    </p>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why14.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why14.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why14.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why14.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="whiteHeader">
                                        Do you have large, complex projects <br>with strict delivery times?
                                    </h4>
                                    <p class="descriptionWhite">
                                        It is impossible to deliver large projects in short time without efficient, automated production. Our machines have delivered some of the biggest, high-profile construction projects around Australia.
                                    </p>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why15.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why15.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why15.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why15.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="whiteHeader">
                                        Is your best worker occupied with <br>cutting?
                                    </h4>
                                    <p class="descriptionWhite">
                                        Cutting is a dull, labour and mind intensive task. Have your best worker to focus on more important things, and leave the dull work to ProfiStop&reg;.
                                    </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg2 md2 sm12 xs12></v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why16.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why16.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why16.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why16.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="whiteHeader">
                                        Are you an owner-run business?
                                    </h4>
                                    <p class="descriptionWhite">
                                        Hiring employees is the most challenging step in the growth story of any business. ProfiStop&reg; is a low-cost way to dramatically increase what you can output yourself and decrease effort.
                                    </p>
                                </v-flex>
                                <v-flex lg4 md4 sm12 xs12>
                                    <!-- <img src="../../assets/images/why_profistop/why17.png"/> -->
                                    <picture>
                                        <source srcset="../../assets/images/why_profistop/why17.webp" type="image/webp">
                                        <source srcset="../../assets/images/why_profistop/why17.png" type="image/png"> 
                                        <img src="../../assets/images/why_profistop/why17.png" alt="Why ProfiStop">
                                    </picture>
                                    <h4 class="whiteHeader">
                                        We hear stories like this every day.
                                    </h4>
                                    <p class="descriptionWhite">
                                        ProfiStop&reg; purchase for our Customers is typically a transformational investment paying itself off often in a matter of months.
                                    </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex lg12 md12 sm12 xs12 align-center>
                                    <button class="findOutMore" @click="openContactModal()">Find Out More</button>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-layout>
                </blue>
			</v-layout>
		</v-container>
        <contact-modal></contact-modal>
        <brochure-modal></brochure-modal>
        <p id="brochureType" style="display: none;">Profistop</p>
	</div>
</template>
<script>
    import Blue from '../helper_components/Blue.vue';
    import White from '../helper_components/White.vue';
    import ContactModal from '../helper_components/ContactModal.vue';
    import BrochureModal from '../helper_components/BrochureModal.vue';
	export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'ProfiStop',
            // all titles will be injected into this template
            titleTemplate: '%s | Why ProfiStop',
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: 'Leading measuring stop and automatic saw solutions for your cutting production. Fast payback and ROI, game-changing efficiency.'
                }
            ],
        },
		components: {
			'blue': Blue,
			'white': White,
            'contact-modal': ContactModal,
            'brochure-modal': BrochureModal
        },
        methods: {
            swipeLeft() {
                this.$router.push('/news')
            },
            swipeRight() {
                this.$router.push('/opticut')
            },
            openContactModal() {
                document.getElementById('contactTrigger').click();
            },
            openBrochureModal() {
                document.getElementById('brochureTrigger').click();
            }
        }
    }
</script>
<style scoped>
    .grid-list-xl {
        margin-right: auto!important;
    }
    #whyProfistop {
        padding: 0;
    }
    .no-webp #whyProfistopTitle {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 0 0 15px 50px;
        max-width: 444px;
        margin: 0 0 0 15%;
        text-align: left;
        background: url(../../assets/images/why_profistop/whyProfistopIco.png) no-repeat -7px 0;
        font-weight: bold;
    }
    .webp #whyProfistopTitle {
        color: #2f2f2f;
        font-size: 31px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 0 0 15px 50px;
        max-width: 444px;
        margin: 0 0 0 15%;
        text-align: left;
        background: url(../../assets/images/why_profistop/whyProfistopIco.webp) no-repeat -7px 0;
        font-weight: bold;
    }
    #whyProfistopContainer {
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    .whiteHeader {
        color: #fff;
        font-size: 16px;
        line-height: 1.5rem;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        font-weight: bold;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 0;
    }
    .descriptionWhite {
        color: #fff;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        padding-left: 20%;
        padding-right: 20%;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
        margin-top: 0;
    }
    .descriptionDark {
        color: #2f2f2f;
        font-size: 16px;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        padding-left: 20%;
        padding-right: 20%;
        margin: 0;
        margin-top: 4px;
        line-height: 25px;
        margin-top: 0;
    }
    .darkHeader {
        color: #2f2f2f;
        font-size: 16px;
        line-height: 1.5rem;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        text-align: center;
        font-weight: bold;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 0;
    }
    .hidden-md-and-up {
        height: 100%;
        width: 100%;
        bottom: 0;
        margin-bottom: -17px;
    }
</style>
        