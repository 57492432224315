import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import Vuetify from 'vuetify';
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import Meta from "vue-meta";
import Vue2TouchEvents from 'vue2-touch-events'
require('../node_modules/vuetify/dist/vuetify.min.css');
require('./modernizr.js');

Vue.use(VueRouter);
Vue.use(Vuetify);
Vue.use(VueAwesomeSwiper /* { default global options } */);
Vue.use(Meta);
Vue.use(Vue2TouchEvents);
const router = new VueRouter({
	mode: 'history',
	routes
});
const root = new Vue({
	el: '#app',
	router,
	render: h => h(App)
});
document.addEventListener("DOMContentLoaded", function() {
  root.$mount("#app");
});